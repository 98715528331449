import {
  CLIENT_INFO_GET_BY_ID,
  CLIENT_INFO_GET_BY_ID_ERROR,
  CLIENT_INFO_GET_BY_ID_SUCCESS,
  CLIENT_INFO_UPDATE,
  CLIENT_INFO_UPDATE_SUCCESS,
  CLIENT_INFO_UPDATE_ERROR,
  CLIENT_INFO_SYNC,
  CLIENT_INFO_SYNC_SUCCESS,
  CLIENT_INFO_SYNC_ERROR,
} from './types';
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
  syncLoading: false,
  lastUpdate: null,
  isClientProfileNull: false,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_INFO_UPDATE:
    case CLIENT_INFO_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        isClientProfileNull: false,
      };
    case CLIENT_INFO_SYNC:
      return {
        ...state,
        loading: false,
        error: null,
        syncLoading: true,
      };
    case CLIENT_INFO_GET_BY_ID_SUCCESS:
    case CLIENT_INFO_SYNC_SUCCESS:
    case CLIENT_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
        syncLoading: false,
        lastUpdate: new Date(),
      };
    case CLIENT_INFO_GET_BY_ID_ERROR:
      const { message } = payload;
      return {
        ...state,
        loading: false,
        error: message,
        syncLoading: false,
        isClientProfileNull: message.startsWith('Does not have a clientProfile object.')
          ? true
          : false,
      };

    case CLIENT_INFO_SYNC_ERROR:
    case CLIENT_INFO_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        syncLoading: false,
        isClientProfileNull: false,
      };

    default:
      return { ...state };
  }
}
