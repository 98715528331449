import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  EMPLOYER_INFO_SYNC,
  EMPLOYER_INFO_GET_BY_ID,
  EMPLOYER_INFO_UPDATE,
  EMPLOYER_INFO_ADD_NEW,
  EMPLOYER_INFO_GET_BY_USER,
} from './types';

import {
  addNewEmployerInfoError,
  addNewEmployerInfoSuccess,
  getEmployerInfoByCurrentUserError,
  getEmployerInfoByCurrentUserSuccess,
  getEmployerInfoByIdError,
  getEmployerInfoByIdSuccess,
  updateEmployerInfoError,
  updateEmployerInfoSuccess,
} from './actions';
import { syncEmployerInfoError, syncEmployerInfoSuccess } from '../actions';

const getEmployerInfoByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EmployerProfile/${id}`, await authHeader());
};

const getEmployerInfoByCurrentUserRequest = async () => {
  return axios.get(
    `${Config.apiServerHost}/api/EmployerProfile/getDefaultMyEmployerInfo`,
    await authHeader()
  );
};

const getEmployerInfoByEmployerReferenceNumberRequest = async employerReferenceNumber => {
  return axios.get(
    `${Config.apiServerHost}/api/EmployerProfile/employerReferenceNumber/${employerReferenceNumber}`,
    await authHeader()
  );
};

const getTransactionLogsByClientIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/client/${id}/transaction/logs`,
    await authHeader()
  );
};

const getTransactionErrorsByClientIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/client/${id}/transaction/live`,
    await authHeader()
  );
};

const putEmployerInfoTransactionIgnoreRequest = async id => {
  return axios.put(
    `${Config.apiServerHost}/api/ClientProfile/client/transaction/${id}/ignore`,
    null,
    await authHeader()
  );
};

const syncViaGoverningTransactionRequest = async requestId => {
  return axios.post(
    `${Config.apiServerHost}/api/ClientProfile/client/transaction/${requestId}/sync`,
    null,
    await authHeader()
  );
};

const syncEmployerInfoRequest = async (id, employerReferenceNumberOverride) => {
  return axios.get(
    `${Config.apiServerHost}/api/EmployerProfile/${id}/sync?employerReferenceNumberOverride=${employerReferenceNumberOverride}`,
    await authHeader()
  );
};

const getEmployerProfileRequest = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/EmployerProfile`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

function* syncEmployerInfo({ payload }) {
  try {
    console.log(payload);
    const { id, employerReferenceNumberOverride } = payload;
    let response = yield call(syncEmployerInfoRequest, id, employerReferenceNumberOverride);
    yield put(syncEmployerInfoSuccess(response.data));
  } catch (error) {
    yield put(syncEmployerInfoError(error?.response?.statusText));
  }
}

function* getEmployerInfoById({ payload }) {
  try {
    let response = yield call(getEmployerInfoByIdRequest, payload);
    yield put(getEmployerInfoByIdSuccess(response.data));
  } catch (error) {
    yield put(getEmployerInfoByIdError(error.response.statusText));
  }
}

function* getEmployerInfoByCurrentUser() {
  try {
    let response = yield call(getEmployerInfoByCurrentUserRequest);
    yield put(getEmployerInfoByCurrentUserSuccess(response.data));
  } catch (error) {
    yield put(getEmployerInfoByCurrentUserError(error.response.statusText));
  }
}

const updateEmployerInfoRequest = async payload => {
  return axios.put(
    `${Config.apiServerHost}/api/EmployerProfile/${payload.employerId}`,
    payload,
    await authHeader()
  );
};

const addNewEmployerInfoRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/EmployerProfile`, payload, await authHeader());
};

function* updateEmployerInfo({ payload }) {
  try {
    let response = yield call(updateEmployerInfoRequest, payload);
    yield put(updateEmployerInfoSuccess(response.data));
  } catch (error) {
    yield put(updateEmployerInfoError(error?.response?.statusText));
  }
}

function* addNewEmployerInfo({ payload }) {
  try {
    // console.log(payload);
    const { employerInfo, history } = payload;
    let response = yield call(addNewEmployerInfoRequest, employerInfo);
    response = yield call(getEmployerInfoByIdRequest, response.data);
    yield put(getEmployerInfoByIdSuccess(response.data));
    history.push('/app/employers');
  } catch (error) {
    yield put(addNewEmployerInfoError(error?.response?.statusText));
  }
}

export function* watchEmployerInfoByCurrentUser() {
  yield takeEvery(EMPLOYER_INFO_GET_BY_USER, getEmployerInfoByCurrentUser);
}

export function* watchGettingClientById() {
  yield takeEvery(EMPLOYER_INFO_GET_BY_ID, getEmployerInfoById);
}

export function* watchUpdatingEmployerInfo() {
  yield takeEvery(EMPLOYER_INFO_UPDATE, updateEmployerInfo);
}

export function* watchAddingEmployerInfo() {
  yield takeEvery(EMPLOYER_INFO_ADD_NEW, addNewEmployerInfo);
}

export function* watchSyncingEmployerInfo() {
  yield takeEvery(EMPLOYER_INFO_SYNC, syncEmployerInfo);
}

export default function* rootSaga() {
  yield all([
    fork(watchGettingClientById),
    fork(watchUpdatingEmployerInfo),
    fork(watchSyncingEmployerInfo),
    fork(watchAddingEmployerInfo),
    fork(watchEmployerInfoByCurrentUser),
  ]);
}

export {
  getTransactionLogsByClientIdRequest,
  getTransactionErrorsByClientIdRequest,
  putEmployerInfoTransactionIgnoreRequest,
  syncViaGoverningTransactionRequest,
  getEmployerProfileRequest,
  getEmployerInfoByEmployerReferenceNumberRequest,
};
