import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import FileSaver from 'file-saver';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  POSTING_DELETE,
  POSTING_EDIT,
  POSTING_GET_BY_ID,
  POSTING_ADD_NEW,
  POSTING_GET_LIST,
  POSTING_RESULT_CHANGE,
  REMOVE_POSTING_CLIENT,
  FILL_POSTING_POSITION,
  POSTING_PUBLISH,
} from './types';
import Config from '../../config';

import {
  getPostingListSuccess,
  getPostingByIdSuccess,
  postingError,
  publishPostingSuccess,
  editPostingSuccess,
  addNewPostingSuccess,
} from './actions';
import { getTransactionNotificationByQueryRequest } from '../transaction-notification/saga';
import { getTransactionNotificationByQuerySuccess } from '../actions';

// Posting
const getPostingListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Posting`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getUpcommingExpiringJobPostingRequest = async () => {
  return axios.get(
    `${Config.apiServerHost}/api/Posting/upcommingExpiringJobPosting`,
    await authHeader()
  );
};

const getPostingDashboardRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Posting/dashboard`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getPostingListByClientIdRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/Posting/fetch`, payload, await authHeader());
};

const publishPostingRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/Posting/publish`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

const syncPostingRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/Posting/sync`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

const getPostingCountByClientIdRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Posting/count`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const addNewPostingReqeust = async actionPlan => {
  return axios.post(`${Config.apiServerHost}/api/Posting`, actionPlan, await authHeader());
};

const editPostingRequest = async actionPlan => {
  return axios.put(`${Config.apiServerHost}/api/Posting`, actionPlan, await authHeader());
};

const fillPostingPositionRequest = async ({ id, positions }) => {
  return axios.put(
    `${Config.apiServerHost}/api/Posting/${id}/fillPosition`,
    positions,
    await authHeader()
  );
};

const deletePostingRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Posting/${id}`, await authHeader());
};

const removePostingClientRequest = async query => {
  let url = `${Config.apiServerHost}/api/Posting/removeClient`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getPostingByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Posting/${id}`, await authHeader());
};

const postingResultChangeRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Posting/${id}/resultChange`, await authHeader());
};

const exportBulkPostingToPDF = async (params, filename) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return await fetch(
    buildUrl(`${Config.apiServerHost}/api/Posting/exportToPDF`, params),
    requestOptions
  )
    .then(response => response.blob())
    .then(data => {
      FileSaver.saveAs(new Blob([data]), filename);
    });
};

const exportPostingToPDF = async (id, filename) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  const url = `${Config.apiServerHost}/api/Posting/exportToPDF/${id}`;

  return await fetch(url, requestOptions)
    .then(response => response.blob())
    .then(data => {
      FileSaver.saveAs(new Blob([data]), filename);
    });
};

function* getPostingList({ payload }) {
  try {
    const response = yield call(getPostingListByClientIdRequest, payload);
    yield put(getPostingListSuccess(response.data));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* addNewPosting({ payload }) {
  try {
    const { item, history } = payload;

    if (history) {
      yield call(addNewPostingReqeust, item);
      yield put(addNewPostingSuccess(item));
      history.push('/app/posting/default');
    }
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* editPosting({ payload }) {
  try {
    const { item, history } = payload;
    if (history) {
      yield call(editPostingRequest, item);
      yield put(editPostingSuccess(item));
      history.push('/app/posting/default');
    }
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* fillPostingPosition({ payload }) {
  try {
    const { id, positions } = payload;
    let response = yield call(fillPostingPositionRequest, { id, positions });
    response = yield call(getPostingListByClientIdRequest, {});
    yield put(getPostingListSuccess(response.data));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* getPostingById({ payload }) {
  try {
    const response = yield call(getPostingByIdRequest, payload);
    yield put(getPostingByIdSuccess(response.data));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* publishPosting({ payload }) {
  try {
    const response = yield call(publishPostingRequest, payload);
    yield put(publishPostingSuccess(response.data));

    const secondResponse = yield call(getTransactionNotificationByQueryRequest);
    yield put(getTransactionNotificationByQuerySuccess(secondResponse.data));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* postingResultChange({ payload }) {
  try {
    const { filter, page, pageSize, data } = payload;
    const query = {
      filter: filter ?? '',
      page,
      pageSize,
    };
    yield call(postingResultChangeRequest, data);
    const response = yield call(getPostingListByClientIdRequest, query);
    yield put(getPostingListSuccess(response.data));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* deletePosting({ payload }) {
  try {
    const { employerId, clientId, filter, data } = payload;
    const query = {
      filter: filter ?? null,
      clientId: clientId ?? null,
      employerId: employerId ?? null,
    };

    yield call(deletePostingRequest, data);
    const response = yield call(getPostingListByClientIdRequest, query);
    yield put(getPostingListSuccess(response.data));
  } catch (error) {
    console.log(error.response.data);
    yield put(postingError(error.response.statusText));
  }
}

function* removePostingClient({ payload }) {
  try {
    const { clientId, postingId } = payload;
    const data = {
      clientId: clientId ?? '',
      postingId: postingId ?? '',
    };

    const query = {
      clientId: clientId ?? '',
    };

    yield call(removePostingClientRequest, data);
    const response = yield call(getPostingListByClientIdRequest, query);
    yield put(getPostingListSuccess(response.data));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

export function* watchPostingResultChange() {
  yield takeEvery(POSTING_RESULT_CHANGE, postingResultChange);
}

export function* watchPostingList() {
  yield takeEvery(POSTING_GET_LIST, getPostingList);
}

export function* watchAddNewPosting() {
  yield takeEvery(POSTING_ADD_NEW, addNewPosting);
}

export function* watchPostingById() {
  yield takeEvery(POSTING_GET_BY_ID, getPostingById);
}

export function* watchEditPosting() {
  yield takeEvery(POSTING_EDIT, editPosting);
}

export function* watchDeletePosting() {
  yield takeEvery(POSTING_DELETE, deletePosting);
}

export function* watchRemovePostingClient() {
  yield takeEvery(REMOVE_POSTING_CLIENT, removePostingClient);
}

export function* watchFillPostingPosition() {
  yield takeEvery(FILL_POSTING_POSITION, fillPostingPosition);
}

export function* watchPublishPosting() {
  yield takeEvery(POSTING_PUBLISH, publishPosting);
}

export default function* rootSaga() {
  yield all([
    fork(watchPostingResultChange),
    fork(watchPostingList),
    fork(watchAddNewPosting),
    fork(watchPostingById),
    fork(watchEditPosting),
    fork(watchDeletePosting),
    fork(watchRemovePostingClient),
    fork(watchFillPostingPosition),
    fork(watchPublishPosting),
  ]);
}

export {
  getPostingListByClientIdRequest,
  getPostingListRequest,
  editPostingRequest,
  getPostingCountByClientIdRequest,
  exportBulkPostingToPDF,
  exportPostingToPDF,
  getPostingDashboardRequest,
  getUpcommingExpiringJobPostingRequest,
  syncPostingRequest,
};
