import {
  CASE_NOTE2_ADD_NEW,
  CASE_NOTE2_ADD_NEW_SUCCESS,
  CASE_NOTE2_ADD_NEW_ERROR,
  CASE_NOTE2_DELETE,
  CASE_NOTE2_DELETE_ERROR,
  CASE_NOTE2_DELETE_SUCCESS,
  CASE_NOTE2_EDIT,
  CASE_NOTE2_EDIT_ERROR,
  CASE_NOTE2_EDIT_SUCCESS,
  CASE_NOTE2_GET_BY_ID,
  CASE_NOTE2_GET_BY_ID_ERROR,
  CASE_NOTE2_GET_BY_ID_SUCCESS,
  CASE_NOTE2_ADD_NEW_REQUEST,
  CASE_NOTE2_QUERY,
  CASE_NOTE2_QUERY_SUCCESS,
  CASE_NOTE2_QUERY_ERROR,
  CASE_NOTE2_AGGREGATE,
  CASE_NOTE2_AGGREGATE_ERROR,
  CASE_NOTE2_AGGREGATE_SUCCESS,
  EMPLOYER_CASE_NOTE2_EDIT,
  EMPLOYER_CASE_NOTE2_EDIT_SUCCESS,
  EMPLOYER_CASE_NOTE2_EDIT_ERROR,
  EMPLOYER_CASE_NOTE2_GET_BY_ID,
  EMPLOYER_CASE_NOTE2_GET_BY_ID_SUCCESS,
  EMPLOYER_CASE_NOTE2_GET_BY_ID_ERROR,
  CASE_NOTE2_NOTIFICATION_CLOSE,
} from '../actions';

const initialState = {
  caseNoteAggregate: null,
  caseNotes: [],
  caseNote: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
  showNotification: false,
};

export default function reducerState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CASE_NOTE2_GET_BY_ID:
    case EMPLOYER_CASE_NOTE2_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case CASE_NOTE2_GET_BY_ID_SUCCESS:
    case EMPLOYER_CASE_NOTE2_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        caseNote: payload,
      };

    case CASE_NOTE2_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };

    case CASE_NOTE2_AGGREGATE:
    case CASE_NOTE2_QUERY:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case CASE_NOTE2_AGGREGATE_SUCCESS:
      return {
        ...state,
        caseNoteAggregate: payload,
        loading: false,
      };
    case CASE_NOTE2_QUERY_SUCCESS:
      return {
        ...state,
        caseNotes: payload,
        loading: false,
      };
    case CASE_NOTE2_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case CASE_NOTE2_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        caseNote: payload,
        caseNotes: state.caseNotes.concat(payload),
        addSuccess: true,
      };

    case EMPLOYER_CASE_NOTE2_EDIT:
    case CASE_NOTE2_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case EMPLOYER_CASE_NOTE2_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        caseNotes: payload,
        error: null,
        editSuccess: true,
      };
    case CASE_NOTE2_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        caseNotes: state.caseNotes.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        caseNote: payload,
        error: null,
        editSuccess: true,
      };
    case CASE_NOTE2_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
      };
    case CASE_NOTE2_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        caseNotes: state.caseNotes.filter(c => c.id !== payload),
        deleteSuccess: true,
      };
    case CASE_NOTE2_NOTIFICATION_CLOSE:
      return {
        ...state,
        error: null,
        showNotification: false,
      };
    case CASE_NOTE2_QUERY_ERROR:
    case CASE_NOTE2_DELETE_ERROR:
    case CASE_NOTE2_ADD_NEW_ERROR:
    case CASE_NOTE2_EDIT_ERROR:
    case CASE_NOTE2_GET_BY_ID_ERROR:
    case CASE_NOTE2_AGGREGATE_ERROR:
    case EMPLOYER_CASE_NOTE2_EDIT_ERROR:
    case EMPLOYER_CASE_NOTE2_GET_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        showNotification: true,
      };

    default:
      return { ...state };
  }
}
