import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { authAccessHeader } from '../../helpers/auth-access-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { Auth } from 'aws-amplify';
import axios from 'axios';

import {
  USER_GET_LIST,
  USER_ADD_OR_UPDATE_ITEM,
  USER_DELETE_ITEM,
  USER_RESET_PASSWORD,
  USER_ROLE_GET_BY_ID,
  USER_ROLE_UPDATE,
  USER_ROLE_DELETE_LIST,
  USER_PROFILE_GET_BY_ID,
  USER_PROFILE_PICTURE_UPDATE,
  USER_DISABLED_OR_ENABLED,
} from '../actions';

import {
  getUserListSuccess,
  getUserListError,
  AddorUpdateUserItemError,
  deleteUserItemError,
  getUserRoleByIdSuccess,
  getUserRoleByIdError,
  getUserProfileByIdSuccess,
  getUserProfileByIdError,
} from './actions';

export const getMfaQrCode = async payload => {
  let url = `${Config.apiServerHost}/api/account/mfa-qr-code`;
  return axios.get(buildUrl(url, payload), await authAccessHeader());
};

export const getMfaVerifyCode = async payload => {
  let url = `${Config.apiServerHost}/api/account/mfa-verify-code`;
  return axios.get(buildUrl(url, payload), await authAccessHeader());
};

export const getMfaDisableCode = async payload => {
  let url = `${Config.apiServerHost}/api/account/mfa-disable-code`;
  return axios.get(buildUrl(url, payload), await authAccessHeader());
};

const getUserListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/account/fetch`;
  return axios.get(buildUrl(url, payload), await authHeader());
};


const getUserRoleByIdRequest = async id => {
  return await axios.get(
    `${Config.apiServerHost}/api/account/getuserwithuserroles/${id}`,
    await authHeader()
  );
};

function* getUserRoleById({ payload }) {
  try {
    const response = yield call(getUserRoleByIdRequest, payload);
    yield put(getUserRoleByIdSuccess(response.data));
  } catch (error) {
    yield put(getUserRoleByIdError(error));
  }
}

const disableOrEnableUserByIdRequest = async id => {
  return await axios.get(
    `${Config.apiServerHost}/api/account/disableOrEnable/${id}`,
    await authHeader()
  );
};

const getUserProfileByIdRequest = async id => {
  console.log('GOT HERE!');
  return await axios.get(`${Config.apiServerHost}/api/account/user/${id}`, await authHeader());
};

function* disableOrEnableUserById({ payload }) {
  try {
    yield call(disableOrEnableUserByIdRequest, payload);
    const response = yield call(getUserListRequest, '');
    yield put(getUserListSuccess(response.data));
  } catch (error) {
    yield put(getUserProfileByIdError(error));
  }
}

function* getUserProfileById({ payload }) {
  try {
    const response = yield call(getUserProfileByIdRequest, payload);
    yield put(getUserProfileByIdSuccess(response.data));
  } catch (error) {
    yield put(getUserProfileByIdError(error));
  }
}

function* getUserListItems({ payload }) {
  try {
    const response = yield call(getUserListRequest, payload);
    yield put(getUserListSuccess(response.data));
  } catch (error) {
    yield put(getUserListError(error));
  }
}

const poshUserListRequest = async user => {
  return await axios.post(`${Config.apiServerHost}/api/account/register`, user, await authHeader());
};
const updateUserRequest = async user => {
  return await axios.put(
    `${Config.apiServerHost}/api/account/user/${user.id}`,
    user,
    await authHeader()
  );
};

function* AddorUpdateUserItem({ payload }) {
  try {
    if (payload.status) {
      yield call(poshUserListRequest, payload.item);
    } else {
      yield call(updateUserRequest, payload.item);
    }
    const res = yield call(getUserListRequest);
    yield put(getUserListSuccess(res.data));
    if (typeof payload.item.id !== 'undefined' && payload.item.id !== null) {
      const resProfle = yield call(getUserProfileByIdRequest, payload.item.id);
      yield put(getUserProfileByIdSuccess(resProfle.data));
    }
  } catch (error) {
    yield put(AddorUpdateUserItemError(error));
  }
}
const UpdateUserPwdRequestRequest = async user => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(cognitoUser, user.currentPassword, user.newPassword);
};
function* UpdateUserPwdRequest({ payload }) {
  try {
    yield call(UpdateUserPwdRequestRequest, payload);
    const res = yield call(getUserListRequest);
    yield put(getUserListSuccess(res.data));
  } catch (error) {
    yield put(AddorUpdateUserItemError(error));
  }
}

const handleUpdateUserPictureRequest = async user => {
  return await axios.put(
    `${Config.apiServerHost}/api/account/UpdateUserPicture`,
    user,
    await authHeader()
  );
};
function* handleUpdateUserPicture({ payload }) {
  try {
    yield call(handleUpdateUserPictureRequest, payload);
    const res = yield call(getUserProfileByIdRequest, payload.id);
    yield put(getUserProfileByIdSuccess(res.data));
  } catch (error) {
    yield put(getUserProfileByIdError(error));
  }
}

const UpdateUserRole = async user => {
  return await axios.put(
    `${Config.apiServerHost}/api/account/addroletoUser/${user.userId}`,
    { id: user.id },
    await authHeader()
  );
};
function* editUserRole({ payload }) {
  try {
    yield call(UpdateUserRole, payload);

    const res = yield call(getUserRoleByIdRequest, payload.userId);
    yield put(getUserRoleByIdSuccess(res.data));
  } catch (error) {
    yield put(getUserRoleByIdError(error));
  }
}

const deleteUserRoleListRequest = async user => {
  return await axios.delete(
    `${Config.apiServerHost}/api/account/deleterolefromuser/${user.userId}/${user.roleId}`,
    await authHeader()
  );
};
function* deleteUserRoleItem({ payload }) {
  try {
    yield call(deleteUserRoleListRequest, payload);
    const res = yield call(getUserRoleByIdRequest, payload.userId);
    yield put(getUserRoleByIdSuccess(res.data));
  } catch (error) {
    yield put(getUserRoleByIdError(error));
  }
}

const deleteUserListRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/account/user/${id}`, await authHeader());
};
function* deleteUserItem({ payload }) {
  try {
    yield call(deleteUserListRequest, payload);
    const res = yield call(getUserListRequest, '');
    yield put(getUserListSuccess(res.data));
  } catch (error) {
    yield put(deleteUserItemError(error));
  }
}

export function* watchDisableOrEnableUserById() {
  yield takeEvery(USER_DISABLED_OR_ENABLED, disableOrEnableUserById);
}

export function* watchGetList() {
  yield takeEvery(USER_GET_LIST, getUserListItems);
}

export function* wathcADD_OR_UPDATEItem() {
  yield takeEvery(USER_ADD_OR_UPDATE_ITEM, AddorUpdateUserItem);
}
export function* wathcDeleteItem() {
  yield takeEvery(USER_DELETE_ITEM, deleteUserItem);
}
export function* wathcUpdateUserPwdRequest() {
  yield takeEvery(USER_RESET_PASSWORD, UpdateUserPwdRequest);
}
export function* wathcUpdateUserRole() {
  yield takeEvery(USER_ROLE_UPDATE, editUserRole);
}
export function* wathcGetUserRole() {
  yield takeEvery(USER_ROLE_GET_BY_ID, getUserRoleById);
}

export function* wathcDeleteUserRole() {
  yield takeEvery(USER_ROLE_DELETE_LIST, deleteUserRoleItem);
}

export function* wathcGetUserProfile() {
  yield takeEvery(USER_PROFILE_GET_BY_ID, getUserProfileById);
}

export function* wathcUserProfilePictureUpdate() {
  yield takeEvery(USER_PROFILE_PICTURE_UPDATE, handleUpdateUserPicture);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcADD_OR_UPDATEItem),
    fork(wathcDeleteItem),
    fork(wathcUpdateUserPwdRequest),
    fork(wathcUpdateUserRole),
    fork(wathcGetUserRole),
    fork(wathcDeleteUserRole),
    fork(wathcGetUserProfile),
    fork(wathcUserProfilePictureUpdate),
    fork(watchDisableOrEnableUserById),
  ]);
}