import { SEVERITY } from '../../helpers/Utils';
import {
  JOB_BOARD_GET_BY_QUERY,
  JOB_BOARD_GET_BY_QUERY_SUCCESS,
  JOB_BOARD_GET_BY_QUERY_ERROR,
  JOB_BOARD_AUTHORIZE,
  JOB_BOARD_AUTHORIZE_SUCCESS,
  JOB_BOARD_AUTHORIZE_ERROR,
  CLEAR_JOB_BOARD_MESSAGE,
} from './types';
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
  message: null,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case JOB_BOARD_GET_BY_QUERY:
    case JOB_BOARD_AUTHORIZE:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };

    case JOB_BOARD_GET_BY_QUERY_SUCCESS:
      const { itemCount, pageCount, total, data } = payload;
      return {
        ...state,
        loading: false,
        items: data,
        total,
        itemCount,
        pageCount,
      };

    case JOB_BOARD_AUTHORIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: {
          title: 'Employer Connect Request',
          message: 'Sending connect request for Job Board',
          severity: SEVERITY.SUCCESS,
        },
      };
    case CLEAR_JOB_BOARD_MESSAGE: {
      return {
        ...state,
        loading: false,
        message: null,
      };
    }

    case JOB_BOARD_GET_BY_QUERY_ERROR:
    case JOB_BOARD_AUTHORIZE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
