import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CASE_NOTE2_DELETE,
  CASE_NOTE2_EDIT,
  CASE_NOTE2_GET_BY_ID,
  CASE_NOTE2_ADD_NEW,
  CASE_NOTE2_QUERY,
  CASE_NOTE2_AGGREGATE,
  EMPLOYER_CASE_NOTE2_EDIT,
  EMPLOYER_CASE_NOTE2_GET_BY_ID,
  getClientServicePlansSuccess,
  getClientServicePlanSuccess,
  getClientLeftSidebarSuccess,
} from '../actions';

import {
  editCaseNote2Success,
  editCaseNote2Error,
  addNewCaseNote2Success,
  addNewCaseNote2Error,
  deleteCaseNote2Success,
  deleteCaseNote2Error,
  getCaseNote2ByIdSuccess,
  getCaseNote2QuerySuccess,
  getCaseNote2QueryError,
  getCaseNotesAggregateSuccess,
  getCaseNotesAggregateError,
  editEmployerCaseNote2Success,
  editEmployerCaseNote2Error,
  getEmployerCaseNote2ByIdSuccess,
  getEmployerCaseNote2ByIdError,
} from './actions';
import {
  getClientLeftSidebarRequest,
  getClientServicePlanRequest,
  getClientServicePlansRequest,
} from '../client/saga';

const getCaseNotesAggregateRequest = async query => {
  const url = `${Config.apiServerHost}/api/CaseNote/aggregate`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getCaseNote2QueryRequest = async query => {
  const url = `${Config.apiServerHost}/api/CaseNote/search`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const addNewCaseNote2Reqeust = async caseNote2 => {
  return axios.post(`${Config.apiServerHost}/api/CaseNote`, caseNote2, await authHeader());
};

const editCaseNote2Request = async CaseNote2 => {
  return axios.put(`${Config.apiServerHost}/api/CaseNote`, CaseNote2, await authHeader());
};

const editEmployerCaseNote2Request = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/EmployerCaseNote`, caseNote, await authHeader());
};

const deleteCaseNote2Request = async id => {
  return axios.delete(`${Config.apiServerHost}/api/CaseNote/${id}`, await authHeader());
};

const getCaseNote2ByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/CaseNote/${id}`, await authHeader());
};

const getEmployerCaseNote2ByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EmployerCaseNote/${id}`, await authHeader());
};

function* getCaseNotesAggregate({ payload }) {
  try {
    const response = yield call(getCaseNotesAggregateRequest, payload);
    yield put(getCaseNotesAggregateSuccess(response.data));
  } catch (error) {
    yield put(getCaseNotesAggregateError(error.response.statusText));
  }
}

function* getCaseNote2Query({ payload }) {
  try {
    const response = yield call(getCaseNote2QueryRequest, payload);
    yield put(getCaseNote2QuerySuccess(response.data));
  } catch (error) {
    yield put(getCaseNote2QueryError(error.response.statusText));
  }
}

function* addNewCaseNote2({ payload }) {
  try {
    const { clientId, servicePlanHeaderId } = payload;

    let response = yield call(addNewCaseNote2Reqeust, payload);
    response = yield call(getCaseNote2ByIdRequest, response.data);
    yield put(addNewCaseNote2Success(response.data));

    // Call to refresh life sidebar and breadscrum
    response = yield call(getClientLeftSidebarRequest, { clientId });
    yield put(getClientLeftSidebarSuccess(response.data));

    response = yield call(getClientServicePlanRequest, { clientId, id: servicePlanHeaderId });
    yield put(getClientServicePlanSuccess(response.data));
  } catch (error) {
    yield put(addNewCaseNote2Error(error.response.statusText));
  }
}

function* editCaseNote2({ payload }) {
  try {
    const { clientId, servicePlanHeaderId } = payload;
    let response = yield call(editCaseNote2Request, payload);
    response = yield call(getCaseNote2ByIdRequest, payload.id);
    yield put(editCaseNote2Success(response.data));

    // Call to refresh life sidebar and breadscrum
    response = yield call(getClientLeftSidebarRequest, { clientId });
    yield put(getClientLeftSidebarSuccess(response.data));

    response = yield call(getClientServicePlanRequest, { clientId, id: servicePlanHeaderId });
    yield put(getClientServicePlanSuccess(response.data));
  } catch (error) {
    yield put(editCaseNote2Error(error.response.statusText));
  }
}

function* editEmployerCaseNote2({ payload }) {
  try {
    const { caseNote, query } = payload;
    let response = yield call(editEmployerCaseNote2Request, caseNote);
    response = yield call(getCaseNote2QueryRequest, query);
    yield put(editEmployerCaseNote2Success(response.data));
  } catch (error) {
    yield put(editEmployerCaseNote2Error(error.response.statusText));
  }
}

function* getCaseNote2ById({ payload }) {
  try {
    const response = yield call(getCaseNote2ByIdRequest, payload);
    yield put(getCaseNote2ByIdSuccess(response.data));
  } catch (error) {
    yield put(editCaseNote2Error(error.response.statusText));
  }
}

function* getEmployerCaseNote2ById({ payload }) {
  try {
    const response = yield call(getEmployerCaseNote2ByIdRequest, payload);
    yield put(getEmployerCaseNote2ByIdSuccess(response.data));
  } catch (error) {
    yield put(getEmployerCaseNote2ByIdError(error.response.statusText));
  }
}

function* deleteCaseNote2({ payload }) {
  try {
    const response = yield call(deleteCaseNote2Request, payload);
    yield put(deleteCaseNote2Success(response.data));
  } catch (error) {
    yield put(deleteCaseNote2Error(error.response.statusText));
  }
}

export function* watchCaseNotesAggregate() {
  yield takeEvery(CASE_NOTE2_AGGREGATE, getCaseNotesAggregate);
}

export function* watchCaseNote2Query() {
  yield takeEvery(CASE_NOTE2_QUERY, getCaseNote2Query);
}

export function* watchAddNewCaseNote2() {
  yield takeEvery(CASE_NOTE2_ADD_NEW, addNewCaseNote2);
}

export function* watchCaseNote2ById() {
  yield takeEvery(CASE_NOTE2_GET_BY_ID, getCaseNote2ById);
}

export function* watchEmployerCaseNote2ById() {
  yield takeEvery(EMPLOYER_CASE_NOTE2_GET_BY_ID, getEmployerCaseNote2ById);
}

export function* watchEditCaseNote2() {
  yield takeEvery(CASE_NOTE2_EDIT, editCaseNote2);
}

export function* watchEditEmployerCaseNote2() {
  yield takeEvery(EMPLOYER_CASE_NOTE2_EDIT, editEmployerCaseNote2);
}

export function* watchDeleteCaseNote2() {
  yield takeEvery(CASE_NOTE2_DELETE, deleteCaseNote2);
}

export default function* rootSaga() {
  yield all([
    fork(watchCaseNotesAggregate),
    fork(watchCaseNote2Query),
    fork(watchAddNewCaseNote2),
    fork(watchCaseNote2ById),
    fork(watchEmployerCaseNote2ById),
    fork(watchEditCaseNote2),
    fork(watchEditEmployerCaseNote2),
    fork(watchDeleteCaseNote2),
  ]);
}

export {
  getCaseNote2QueryRequest,
  editCaseNote2Request,
  getCaseNote2ByIdRequest,
  deleteCaseNote2Request,
};
