import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  EAP_GET_BY_ID,
  EAP_UPDATE,
  EAP_SYNC,
  GET_EAP_DOCUMENT_LIST,
  GET_EAP_LIST,
  SAVE_EAP_PLAN_ITEM_ATTACHMENT,
} from './types';

import {
  getEAPByIdError,
  getEAPByIdSuccess,
  getEapDocumentListSuccess,
  getEapListSuccess,
  updateEAPError,
  updateEAPSuccess,
  syncEAPSuccess,
  syncEAPError,
} from './actions';
import { getClientLeftSidebarSuccess, getClientServicePlanSuccess } from '../actions';
import { getClientLeftSidebarRequest, getClientServicePlanRequest } from '../client/saga';

const getSSMUserRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/EAPDetail/ssmUser`, await authHeader());
};

const getActiveFsApprovals = async () => {
  return axios.get(`${Config.apiServerHost}/api/Approvals/fs/active`, await authHeader());
};

const getActivePbfApprovals = async () => {
  return axios.get(`${Config.apiServerHost}/api/Approvals/pbf/active`, await authHeader());
};

const getLiveEAPTransactions = async () => {
  return axios.get(`${Config.apiServerHost}/api/EAPDetail/transaction/live`, await authHeader());
};

const savePlannedItemAttachmentRequest = async (data, documentType) => {
  return axios.post(
    `${Config.apiServerHost}/api/EAPDetail/savePlannedItemAttachments?documentType=${documentType}`,
    data,
    await authHeader()
  );
};

const savePlannedItemAttachmentFromExistingRequest = async (data, documentType) => {
  return axios.post(
    `${Config.apiServerHost}/api/EAPDetail/savePlannedItemAttachmentsFromExisting?documentType=${documentType}`,
    data,
    await authHeader()
  );
};

const getEAPByIdRequest = async payload => {
  const { clientId, servicePlanHeaderId } = payload;
  return axios.get(
    `${Config.apiServerHost}/api/EAPDetail/client/${clientId}/servicePlan/${servicePlanHeaderId}`,
    await authHeader()
  );
};

const getEaplistRequest = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/EAPDetail`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

const getEapDocumentsRequest = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/EAPDetail/document`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

function* getEapList({ payload }) {
  try {
    let response = yield call(getEaplistRequest, payload);
    yield put(getEapListSuccess(response.data));
  } catch (error) {
    yield put(getEAPByIdError(error.response.statusText));
  }
}

function* getEapDocumentList({ payload }) {
  try {
    let response = yield call(getEapDocumentsRequest, payload);
    yield put(getEapDocumentListSuccess(response.data));
  } catch (error) {
    yield put(getEAPByIdError(error.response.statusText));
  }
}

function* getEAPById({ payload }) {
  try {
    let response = yield call(getEAPByIdRequest, payload);
    yield put(getEAPByIdSuccess(response.data));
  } catch (error) {
    yield put(getEAPByIdError(error.response.statusText));
  }
}

const updateEAPRequest = async eapDetail => {
  return axios.put(`${Config.apiServerHost}/api/EAPDetail`, eapDetail, await authHeader());
};

const syncEAPRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/EAPDetail/sync?clientId=${data.clientId}&servicePlanHeaderId=${data.servicePlanHeaderId}&clientReferenceNumberOverride=${data.clientReferenceNumberOverride}`,
    '{}',
    await authHeader()
  );
};

function* updateEAP({ payload }) {
  try {
    let response = yield call(updateEAPRequest, payload);
    const { clientId, servicePlanHeaderId } = payload;

    yield put(getEapListSuccess(response.data));

    // Call to refresh life sidebar and breadscrum
    response = yield call(getClientLeftSidebarRequest, { clientId });
    yield put(getClientLeftSidebarSuccess(response.data));

    response = yield call(getClientServicePlanRequest, { clientId, id: servicePlanHeaderId });
    yield put(getClientServicePlanSuccess(response.data));
  } catch (error) {
    yield put(updateEAPError(error?.response?.statusText));
  }
}

function* syncEAP({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, clientReferenceNumberOverride } = payload;
    let response = yield call(syncEAPRequest, {
      clientId,
      servicePlanHeaderId,
      clientReferenceNumberOverride,
    });
    response = yield call(getEaplistRequest, { clientId, servicePlanHeaderId });
    yield put(getEapListSuccess(response.data));
  } catch (error) {
    yield put(syncEAPError(error?.response?.statusText));
  }
}

function* savePlannedItemAttachment({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, data } = payload;
    let response = yield call(savePlannedItemAttachmentRequest, data);
    response = yield call(getEaplistRequest, { clientId, servicePlanHeaderId });
    console.log(response.data);
    yield put(getEapListSuccess(response.data));
  } catch (error) {
    yield put(updateEAPError(error?.response?.statusText));
  }
}

export function* watchGettingEapList() {
  yield takeEvery(GET_EAP_LIST, getEapList);
}

export function* watchGettingEapDocumentList() {
  yield takeEvery(GET_EAP_DOCUMENT_LIST, getEapDocumentList);
}

export function* watchGettingClientById() {
  yield takeEvery(EAP_GET_BY_ID, getEAPById);
}

export function* watchUpdatingEAP() {
  yield takeEvery(EAP_UPDATE, updateEAP);
}

export function* watchSyncingEAP() {
  yield takeEvery(EAP_SYNC, syncEAP);
}

export function* watchSavePlannedItemAttachment() {
  yield takeEvery(SAVE_EAP_PLAN_ITEM_ATTACHMENT, savePlannedItemAttachment);
}

export default function* rootSaga() {
  yield all([
    fork(watchGettingClientById),
    fork(watchUpdatingEAP),
    fork(watchGettingEapList),
    fork(watchSavePlannedItemAttachment),
    fork(watchGettingEapDocumentList),
    fork(watchSyncingEAP),
  ]);
}

export {
  getSSMUserRequest,
  savePlannedItemAttachmentRequest,
  savePlannedItemAttachmentFromExistingRequest,
  getLiveEAPTransactions,
  getActiveFsApprovals,
  getActivePbfApprovals,
};
