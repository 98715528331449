import React, { useReducer, useMemo, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { getCognitoSettings } from '../services/settings-service';

const SettingsContext = React.createContext();

const initialState = {
  appInitialized: false,
  company: {},
  awsConfig: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'company': {
      const awsConfig = {
        Auth: {
          identityPoolId: action.payload.identityPoolId,
          region: action.payload.region,
          userPoolId: action.payload.userPoolId,
          userPoolWebClientId: action.payload.userPoolWebClientId,
          mandatorySignIn: true,
          authenticationFlowType: 'CUSTOM_AUTH',
        },
        Storage: {
          bucket: `${action.payload.client}.escases.live.storage`,
          region: action.payload.region,
        },
      };
      Amplify.configure(awsConfig);
      console.log(`Amplify initialized app for company ${action.payload.client.toUpperCase()}`);
      return {
        ...state,
        appInitialized: true,
        company: action.payload,
        awsConfig,
      };
    }

    default:
      return initialState;
  }
};

const SettingsProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getCompany = async () => {
    const resp = await getCognitoSettings();
    dispatch({
      type: 'company',
      payload: resp.data,
    });
  };

  useEffect(() => {
    getCompany();
  }, []);

  const value = useMemo(
    () => ({
      ...state,
    }),
    // eslint-disable-next-line comma-dangle
    [state]
  );
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SettingsContext.Provider value={value} {...props} />;
};

const useSettingsService = () => {
  const context = React.useContext(SettingsContext);
  if (!context) {
    throw new Error(`useSettingsService must be used within an SettingsProvider`);
  }

  return context;
};

export { SettingsProvider, useSettingsService };
