import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { getSettings } from '../../services/settings-service';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from '../../helpers/Firebase';
import { Auth } from 'aws-amplify';
import { CognitoUserPool, CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';
import Config from '../../config';
import { get } from 'lodash';
import {
  LOGIN_USER,
  RECONNECT_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFY_CODE,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  reconnectingUser,
  reconnectingUserDone,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  verifyCodeSuccess,
  verifyCodeError,
  forgotPasswordError,
  userResetPasswordSuccess,
  userResetPasswordError,
} from './actions';
import { throwStatement } from '@babel/types';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchReconnectUser() {
  yield takeEvery(RECONNECT_USER, loginWithoutEmailPassword);
}

const escasesRegisterAndAuthenticate = async () => {
  return axios.post(`${Config.apiServerHost}/api/account/register`, {}, await authHeader());
};

const refreshToken = async () => {
  await authHeader();
};

const cognitoLoginUser = async (username, password) => {
  console.log({ username, password });

  var cognitoUser = await Auth.signIn(username, password);
  console.log({ response: cognitoUser });
  return cognitoUser;
};

const verifyMFACode = async (cognitoUser, code) => {
  console.log({ cognitoUser, code });

  if (cognitoUser.challengeName == 'CUSTOM_CHALLENGE') {
    console.log('Verifying code for CUSTOM_CHALLENGE...');
    const response = await Auth.sendCustomChallengeAnswer(cognitoUser, code);
    console.log('Cognito User Data ---->', response);
    const session = await Auth.currentSession();
    console.log('Cognito User Access Token --->', session.getAccessToken().getJwtToken());
    console.log('Cognito User Identity Token ---->', session.getIdToken().getJwtToken());
    return response;
  } else if (cognitoUser.challengeName == 'SOFTWARE_TOKEN_MFA') {
    console.log('Verifying code for SOFTWARE_TOKEN_MFA...');

    // const session = await Auth.currentSession();
    // console.log({ session });

    // var poolData = {
    //   UserPoolId: cognitoUser.pool.userPoolId,
    //   ClientId: cognitoUser.pool.clientId,
    // };
    // const userPool = new CognitoUserPool(poolData);
    // const userData = {
    //   Username: cognitoUser.username,
    //   Pool: userPool,
    // };
    // const cognitoUser2 = new CognitoUser(userData);

    // cognitoUser2.respondToAuthChallenge()

    //   respondToAuthChallenge(code, {
    //   onSuccess: result => console.log('WORKS', result),
    //   onFailure: err => console.log('ERR', err),
    // });

    const response = await Auth.confirmSignIn(cognitoUser, code, 'SOFTWARE_TOKEN_MFA');
    console.log('Cognito User Data ---->', response);
    const session = await Auth.currentSession();
    console.log('Cognito User Access Token --->', session.getAccessToken().getJwtToken());
    console.log('Cognito User Identity Token ---->', session.getIdToken().getJwtToken());
    return response;
  }
};

function* loginWithEmailPassword({ payload }) {
  console.log('loginWithEmailPassword', { val: payload.user });

  const { username, password, code, cognitoUser, awsConfig } = payload.user;
  const { history } = payload;
  try {
    if (!(cognitoUser && code)) {
      console.log(
        '1. Initial login with username and password, this will send the OTP password via email'
      );

      //RULES for having both email otp, and authenticator app totp
      //---------------------------------------------------------------
      //Do it once, if the result is:
      //      CUSTOM_CHALLENGE + CUSTOM_AUTH =  Correct - email will be sent with OTP (Do nothing)
      //      SOFTWARE_TOKEN_MFA + USER_SRP_AUTH = Correct - email not send (Do nothing)

      //      CUSTOM_CHALLENGE + USER_SRP_AUTH = Incorrect - email not sent (redo call)
      //      SOFTWARE_TOKEN_MFA + CUSTOM_AUTH = Incorrect - email not send (redo call)

      let cognitoLoginResponse = yield call(cognitoLoginUser, username, password);
      console.log({ cognitoLoginResponse });

      // If incorrect combo, change auth type and redo
      if (
        (cognitoLoginResponse.challengeName === 'SOFTWARE_TOKEN_MFA' &&
          cognitoLoginResponse.authenticationFlowType === 'CUSTOM_AUTH') ||
        (cognitoLoginResponse.challengeName === 'CUSTOM_CHALLENGE' &&
          cognitoLoginResponse.authenticationFlowType === 'USER_SRP_AUTH') ||
        !cognitoLoginResponse.challengeName
      ) {
        // Change authenticationFlowType to correct type
        // var awsConfigure = {
        //   identityPoolId: 'ca-central-1:c475f472-d2c1-44ec-917c-7a4de2663ed4',
        //   region: 'ca-central-1',
        //   userPoolId: 'ca-central-1_nghBu3GX1',
        //   userPoolWebClientId: '15gpelhtsgeuobe7gp2jqs356d',
        //   mandatorySignIn: true,

        //   authenticationFlowType:
        //     cognitoLoginResponse.challengeName === 'SOFTWARE_TOKEN_MFA'
        //       ? 'USER_SRP_AUTH'
        //       : 'CUSTOM_AUTH',
        // };

        awsConfig.authenticationFlowType =
          cognitoLoginResponse.challengeName === 'SOFTWARE_TOKEN_MFA'
            ? 'USER_SRP_AUTH'
            : 'CUSTOM_AUTH';
        Auth.configure(awsConfig);

        //Redo the initial call with correct authenticationFlowType
        cognitoLoginResponse = yield call(cognitoLoginUser, username, password);
        console.log('redo', { cognitoLoginResponse });
      }

      if (cognitoLoginResponse.challengeName === 'CUSTOM_CHALLENGE' && !code) {
        console.log('This requires an email OTP code');
        yield put(
          loginUserSuccess({
            mfaCodeRequired: 'CUSTOM_CHALLENGE',
            cognitoUser: cognitoLoginResponse,
          })
        );
        return false;
      }
      if (cognitoLoginResponse.challengeName === 'SOFTWARE_TOKEN_MFA' && !code) {
        console.log('This requires an authenticator OTP code');
        yield put(
          loginUserSuccess({
            mfaCodeRequired: 'SOFTWARE_TOKEN_MFA',
            cognitoUser: cognitoLoginResponse,
          })
        );
        return false;
      }
    }

    console.log(
      '2. Submit the cognitoUser that was previously returned + the OTP code to finalize the login'
    );

    console.log(
      'verify challenge',
      { cognitoUser },
      { code },
      { challengeName: cognitoUser.challengeName }
    );

    let challengeResponse = yield call(verifyMFACode, cognitoUser, code);

    // handle register & login
    console.log('register/login to escases');
    const response = yield call(escasesRegisterAndAuthenticate);

    if (response.data) {
      const data = response.data;
      console.log('Obtaining permissions:', data.permissions);

      //Re-login to cognito (new claims were added in register call)
      // console.log('Re-login to cognito');
      // cognitoLoginResponse = yield call(cognitoLoginUser, username, password);
      // console.log({ cognitoLoginResponse });

      console.log('refresh and save token');
      yield call(refreshToken);

      //adjust token
      data.token = localStorage.getItem('token');

      console.log('set user state:', data);

      data.mfaCodeRequired = null;
      data.cognitoUser = challengeResponse;
      yield put(loginUserSuccess(data));

      getSettings()
        .then(res => {
          const { AppRegistrationId, AppRegistrationRedirectUrl } = res.data;
          localStorage.setItem('AppRegistrationId', AppRegistrationId);
          localStorage.setItem('AppRegistrationRedirectUrl', AppRegistrationRedirectUrl);
        })
        .catch(err => {
          console.log(err);
        });

      console.log('done auth, redirect to main');

      let hasPermissionOnDefaultPage = data && data.permissions.includes('CompanyInfo.Read');
      console.log('CheckPermission on Default page', hasPermissionOnDefaultPage);

      if (hasPermissionOnDefaultPage) {
        history.push('/app/company-info');
      } else {
        history.push('/app/no-permission');
      }
    } else {
      yield put(loginUserError(response.message));
    }
  } catch (error) {
    console.log({ error });
    const message = get(
      error,
      'response.data.message',
      get(error, 'message', 'Sorry, there was a problem logging in. Please try again.')
    );
    yield put(loginUserError(message));
  }
}

function* loginWithoutEmailPassword({ payload }) {
  console.log('loginWithoutEmailPassword');
  const { history } = payload;
  try {
    yield put(reconnectingUser());
    // handle register & login
    console.log('register/login to escases');
    const response = yield call(escasesRegisterAndAuthenticate);

    if (response.data) {
      const data = response.data;
      console.log('Obtaining permissions:', data.permissions);
      localStorage.setItem('userData', data.permissions);

      console.log('refresh and save token');
      yield call(refreshToken);

      //adjust token
      data.token = localStorage.getItem('token');

      console.log('set user state:', data);
      yield put(loginUserSuccess(data));
      yield put(reconnectingUserDone());

      getSettings()
        .then(res => {
          const { AppRegistrationId, AppRegistrationRedirectUrl } = res.data;
          localStorage.setItem('AppRegistrationId', AppRegistrationId);
          localStorage.setItem('AppRegistrationRedirectUrl', AppRegistrationRedirectUrl);
        })
        .catch(err => {
          console.log(err);
        });

      console.log('Reconnect successful.');
    } else {
      yield put(loginUserError(response.message));
      yield put(reconnectingUserDone());
      history.push('/user/login');
    }
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      get(error, 'message', 'Sorry, there was a problem logging in. Please try again.')
    );
    yield put(loginUserError(message));
    yield put(reconnectingUserDone());
    history.push('/user/login');
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerUser);
}

const registerUserRequest = async user => {
  return await Auth.signUp({
    username: user.username,
    password: user.password,
    attributes: {
      email: user.email,
      given_name: user.firstName,
      family_name: user.lastName,
      [`custom:subdomain`]: window.location.host.split('.')[0],
    },
  });
};

function* registerUser({ payload }) {
  try {
    const { user, history } = payload;
    const response = yield call(registerUserRequest, user);
    yield put(registerUserSuccess(response));
    history.push('/user/confirm');
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async history => {
  await auth
    .signOut()
    .then(authUser => authUser)
    .catch(error => error);
  console.log('Logging out! Navigate to login page.');
  history.push('/user/login');
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem('token');
    localStorage.removeItem('AppRegistrationId');
    localStorage.removeItem('AppRegistrationRedirectUrl');
    const keys = Object.keys(localStorage);
    keys.forEach(k => {
      if (k.indexOf('Cognito') === 0) {
        localStorage.removeItem(k);
      }
    });
  } catch (error) {}
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async email => {
  return await Auth.forgotPassword(email);
};

function* forgotPassword({ payload }) {
  const { email, history } = payload;
  try {
    const response = yield call(forgotPasswordAsync, email);
    if (response) {
      yield put(forgotPasswordSuccess('Please check your e-mail'));
      history.push('/user/confirm');
    } else {
      yield put(forgotPasswordError('Invalid'));
    }
  } catch (error) {
    console.log(error);
    if (error && error.code === 'UserNotFoundException') {
      yield put(forgotPasswordError('User not found'));
    } else {
      yield put(forgotPasswordError(error));
    }
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const verifyCodeRequest = async code => {
  return axios.get(`${Config.apiServerHost}/api/account/verifyCode/${code}`, await authHeader());
};

function* verifyCode({ payload }) {
  const { code, history } = payload;
  try {
    const response = yield call(verifyCodeRequest, code);
    yield put(verifyCodeSuccess(response.data));
    history.push('/user/reset-password');
  } catch (err) {
    const { data, statusText } = err.response;
    const error = {
      message: data,
      statusText,
    };
    yield put(verifyCodeError(error));
  }
}

export function* watchVerifyCode() {
  yield takeEvery(VERIFY_CODE, verifyCode);
}

const resetPasswordAsync = async (username, newPassword, code) => {
  return await Auth.forgotPasswordSubmit(username, code, newPassword);
};

function* resetPassword({ payload }) {
  const { password, history } = payload;
  try {
    yield call(resetPasswordAsync, password.username, password.newPassword, password.code);
    yield put(userResetPasswordSuccess('Password updated'));
    history.push('/user/login');
  } catch (error) {
    console.log(error);
    if (error && error.code === 'UserNotFoundException') {
      yield put(userResetPasswordError('User not found'));
    } else {
      yield put(userResetPasswordError(error));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchReconnectUser),
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchVerifyCode),
  ]);
}
