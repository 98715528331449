const user = {
  navigation: 'UserNavigation.Enable',
  read: 'User.Read',
  write: 'User.Write',
  delete: 'User.Delete',
};

const role = {
  navigation: 'RoleNavigation.Enable',
  read: 'Role.Read',
  write: 'Role.Write',
  delete: 'Role.Delete',
  Export: 'Role.Export',
};

const client = {
  navigation: 'ClientNavigation.Enable',
  viewAll: 'ViewAllClient.Enable',
  read: 'Client.Read',
  write: 'Client.Write',
  delete: 'Client.Delete',
  export: 'Client.Export',
  viewSIN: 'AllowedToViewSIN.Enable',
};

const prospect = {
  general: 'ManageProspectGeneral.Enable',
  hub: 'ManageProspectHub.Enable',
  setting: 'ManageProspectSetting.Enable',
};

const unassistedClient = {
  navigation: 'UnassistedClientNavigation.Enable',
};

const goClient = {
  navigation: 'GoClientNavigation.Enable',
};

const esClient = {
  navigation: 'ESClientNavigation.Enable',
};

const lbsClient = {
  navigation: 'LBSClientNavigation.Enable',
};

const yjcClient = {
  navigation: 'YJCClientNavigation.Enable',
};

const yjcsClient = {
  navigation: 'YJCSClientNavigation.Enable',
};

const cojgClient = {
  navigation: 'COJGClientNavigation.Enable',
};

const seClient = {
  navigation: 'SEClientNavigation.Enable',
};

const odspClient = {
  navigation: 'ODSPClientNavigation.Enable',
};

const yessClient = {
  navigation: 'YESSClientNavigation.Enable',
};

const ariClient = {
  navigation: 'ARIClientNavigation.Enable',
};

const hubClient = {
  navigation: 'HUBClientNavigation.Enable',
};

const saoClient = {
  navigation: 'SAOClientNavigation.Enable',
};

const owepClient = {
  navigation: 'OWEPClientNavigation.Enable',
};

const iesClient = {
  navigation: 'IESClientNavigation.Enable',
};

const assessment = {
  navigation: 'ClientAssessmentTab.Enable',
  read: 'Assessment.Read',
  write: 'Assessment.Write',
  delete: 'Assessment.Delete',
  export: 'Assessment.Export',
};

const clientServicePlan = {
  navigation: 'ClientServicePlanTab.Enable',
  read: 'ActionPlan.Read',
  write: 'ActionPlan.Write',
  delete: 'ActionPlan.Delete',
  export: 'ActionPlan.Export',
};

const actionPlan = {
  navigation: 'ClientActionPlanTab.Enable',
  read: 'ActionPlan.Read',
  write: 'ActionPlan.Write',
  delete: 'ActionPlan.Delete',
  export: 'ActionPlan.Export',
};

const caseNote = {
  navigation: 'ClientCaseNoteTab.Enable',
  read: 'CaseNote.Read',
  write: 'CaseNote.Write',
  delete: 'CaseNote.Delete',
  export: 'CaseNote.Export',
};

const clientFund = {
  navigation: 'ClientFundTab.Enable',
  read: 'ClientFund.Read',
  write: 'ClientFund.Write',
  delete: 'ClientFund.Delete',
  export: 'ClientFund.Export',
};

const clientDocument = {
  navigation: 'ClientDocumentTab.Enable',
  read: 'ClientDocument.Read',
  write: 'ClientDocument.Write',
  delete: 'ClientDocument.Delete',
};

const eventCalendar = {
  navigation: 'EventCalendar.Enable',
  read: 'EventCalendar.Read',
  write: 'EventCalendar.Write',
  delete: 'EventCalendar.Delete',
};

const mileageAndExpense = {
  navigation: 'MileageAndExpenseNavigation.Enable',
  read: 'MileageAndExpense.Read',
  write: 'MileageAndExpense.Write',
  delete: 'MileageAndExpense.Delete',
  export: 'MileageAndExpense.Export',
};

const mileage = {
  read: 'Mileage.Read',
  write: 'Mileage.Write',
  delete: 'Mileage.Delete',
};

const expense = {
  read: 'Expense.Read',
  write: 'Expense.Write',
  delete: 'Expense.Delete',
};

const mileageAndExpenseReport = {
  navigation: 'MileageAndExpenseReport.Enable',
  read: 'MileageAndExpenseReport.Read',
  write: 'MileageAndExpenseReport.Write',
  delete: 'MileageAndExpenseReport.Delete',
  export: 'MileageAndExpenseReport.Export',
};

const cojgContract = {
  navigation: 'COJGContractNavigation.Enable',
  read: 'COJGContract.Read',
  write: 'COJGContract.Write',
  delete: 'COJGContract.Delete',
  export: 'COJGContract.Export',
};

const cojgCheque = {
  tab: 'COJGChequeTab.Enable',
  read: 'COJGCheque.Read',
  write: 'COJGCheque.Write',
  delete: 'COJGCheque.Delete',
};

const cojgCaseNote = {
  tab: 'COJGCaseNoteTab.Enable',
  read: 'COJGCaseNote.Read',
  write: 'COJGCaseNote.Write',
  delete: 'COJGCaseNote.Delete',
  export: 'COJGCaseNote.Export',
};

const esContract = {
  navigation: 'ESContractNavigation.Enable',
  read: 'ESContract.Read',
  write: 'ESContract.Write',
  delete: 'ESContract.Delete',
  export: 'ESContract.Export',
};

const esCheque = {
  tab: 'ESChequeTab.Enable',
  read: 'ESCheque.Read',
  write: 'ESCheque.Write',
  delete: 'ESCheque.Delete',
};

const esCaseNote = {
  tab: 'ESCaseNoteTab.Enable',
  read: 'ESCaseNote.Read',
  write: 'ESCaseNote.Write',
  delete: 'ESCaseNote.Delete',
  export: 'ESCaseNote.Export',
};

const seContract = {
  navigation: 'SEContractNavigation.Enable',
  read: 'SEContract.Read',
  write: 'SEContract.Write',
  delete: 'SEContract.Delete',
  export: 'SEContract.Export',
};

const seCheque = {
  tab: 'SEChequeTab.Enable',
  read: 'SECheque.Read',
  write: 'SECheque.Write',
  delete: 'SECheque.Delete',
};

const seCaseNote = {
  tab: 'SECaseNoteTab.Enable',
  read: 'SECaseNote.Read',
  write: 'SECaseNote.Write',
  delete: 'SECaseNote.Delete',
  export: 'SECaseNote.Export',
};

const budget = {
  navigation: 'BudgetNavigation.Enable',
  read: 'Budget.Read',
  write: 'Budget.Write',
  delete: 'Budget.Delete',
};

const workshop = {
  navigation: 'WorkshopNavigation.Enable',
  read: 'Workshop.Read',
  write: 'Workshop.Write',
  delete: 'Workshop.Delete',
};

const workshopEvent = {
  tab: 'WorkshopEventTab.Enable',
  read: 'WorkshopEvent.Read',
  write: 'WorkshopEvent.Write',
  delete: 'WorkshopEvent.Delete',
};

const eventParticipant = {
  read: 'EventParticipant.Read',
  write: 'EventParticipant.Write',
  delete: 'EventParticipant.Delete',
  export: 'EventParticipant.Export',
};

const clientAggregateReport = {
  navigation: 'ClientAggregateReportNavigation.Enable',
  read: 'ClientAggregateReport.Read',
  write: 'ClientAggregateReport.Write',
  delete: 'ClientAggregateReport.Delete',
  export: 'ClientAggregateReport.Export',
};

const clientReport = {
  navigation: 'ClientReportNavigation.Enable',
  read: 'ClientReport.Read',
  export: 'ClientReport.Export',
};

const clientErrorReport = {
  navigation: 'ClientErrorReportNavigation.Enable',
  read: 'ClientErrorReport.Read',
  write: 'ClientErrorReport.Write',
  export: 'ClientErrorReport.Export',
};

const jobTrackingReport = {
  navigation: 'JobTrackingReportNavigation.Enable',
  read: 'JobTrackingReport.Read',
  export: 'JobTrackingReport.Export',
};

const clientFundReport = {
  navigation: 'ClientFundReportNavigation.Enable',
};

const performanceIndicatorIntake = {
  navigation: 'PerformanceIndicatorIntake.Enable',
};

const supportPayment = {
  navigation: 'SupportPayment.Enable',
};

const workshopReport = {
  navigation: 'WorkshopReport.Enable',
};

const cojgFinancialReport = {
  navigation: 'COJGFinancialReportNavigation.Enable',
  read: 'COJGFinancialReport.Read',
  export: 'COJGFinancialReport.Export',
};

const contractsPaidReport = {
  navigation: 'ContractsPaidReportNavigation.Enable',
  read: 'ContractsPaidReport.Read',
  export: 'ContractsPaidReport.Export',
};

const cojgFundReport = {
  navigation: 'COJGFundReportNavigation.Enable',
  read: 'COJGFundReport.Read',
  export: 'COJGFundReport.Export',
};

const cojgReconciliationReport = {
  navigation: 'COJGReconciliationReportNavigation.Enable',
  read: 'COJGReconciliationReport.Read',
  export: 'COJGReconciliationReport.Export',
};

const esContractReport = {
  navigation: 'ESContractReportNavigation.Enable',
  read: 'ESContractReport.Read',
  export: 'ESContractReport.Export',
};

const esContractOutstandingReport = {
  navigation: 'ESContractOutstandingReportNavigation.Enable',
  read: 'ESContractOutstandingReport.Read',
  export: 'ESContractOutstandingReport.Export',
};

const seContractReport = {
  navigation: 'SEContractReportNavigation.Enable',
  read: 'SEContractReport.Read',
  export: 'SEContractReport.Export',
};

const iesContractReport = {
  navigation: 'IESContractReportNavigation.Enable',
  read: 'IESContractReport.Read',
  export: 'IESContractReport.Export',
};

const iesClientSupportReport = {
  navigation: 'IESClientSupportReportNavigation.Enable',
  read: 'IESClientSupportReport.Read',
  export: 'IESClientSupportReport.Export',
};

const systemConfiguration = {
  navigation: 'SystemConfigurationNavigation.Enable',
  read: 'SystemConfiguration.Read',
  write: 'SystemConfiguration.Write',
};

const geography = {
  navigation: 'GeographyNavigation.Enable',
  read: 'Geography.Read',
  write: 'Geography.Write',
  delete: 'Geography.Delete',
};

const jobType = {
  navigation: 'JobTypeNavigation.Enable',
  read: 'JobType.Read',
  write: 'JobType.Write',
  delete: 'JobType.Delete',
};

const shifts = {
  navigation: 'ShiftsNavigation.Enable',
  read: 'Shifts.Read',
  write: 'Shifts.Write',
  delete: 'Shifts.Delete',
};

const sector = {
  navigation: 'ShiftsNavigation.Enable',
  read: 'Sector.Read',
  write: 'Sector.Write',
  delete: 'Sector.Delete',
};

const aggregate = {
  navigation: 'AggregateNavigation.Enable',
  read: 'Aggregate.Read',
  write: 'Aggregate.Write',
  delete: 'Aggregate.Delete',
};

const mileageSupervisor = {
  navigation: 'MileageSupervisorNavigation.Enable',
  read: 'MileageSupervisor.Read',
  write: 'MileageSupervisor.Write',
  delete: 'MileageSupervisor.Delete',
};

const clientFundSupervisor = {
  navigation: 'ClientFundSupervisorNavigation.Enable',
  read: 'ClientFundSupervisor.Read',
  write: 'ClientFundSupervisor.Write',
  delete: 'ClientFundSupervisor.Delete',
};

const employer = {
  navigation: 'EmployerNavigation.Enable',
  read: 'Employer.Read',
  write: 'Employer.Write',
  delete: 'Employer.Delete',
  export: 'Employer.Export',
};

const employerContact = {
  tab: 'EmployerContactTab.Enable',
  read: 'EmployerContact.Read',
  write: 'EmployerContact.Write',
  delete: 'EmployerContact.Delete',
};

const employerCaseNote = {
  tab: 'EmployerCaseNoteTab.Enable',
  read: 'EmployerCaseNote.Read',
  write: 'EmployerCaseNote.Write',
  delete: 'EmployerCaseNote.Delete',
  export: 'EmployerCaseNote.Export',
};

const trainer = {
  navigation: 'TrainerNavigation.Enable',
  read: 'Trainer.Read',
  write: 'Trainer.Write',
  delete: 'Trainer.Delete',
  export: 'Trainer.Export',
};

const posting = {
  navigation: 'PostingNavigation.Enable',
  read: 'Posting.Read',
  write: 'Posting.Write',
  delete: 'Posting.Delete',
};

const camsImport = {
  navigation: 'CaMSImportNavigation.Enable',
  read: 'CaMSImport.Read',
  write: 'CaMSImport.Write',
  delete: 'CaMSImport.Delete',
};

const todo = {
  navigation: 'ToDoNavigation.Enable',
  read: 'ToDo.Read',
  write: 'ToDo.Write',
  delete: 'ToDo.Delete',
};

const formDocument = {
  tab: 'FormDocumentTab.Enable',
  read: 'FormDocument.Read',
  write: 'FormDocument.Write',
  delete: 'FormDocument.Delete',
};

const additionalInfo = {
  tab: 'AdditionalInfo.Enable',
  read: 'AdditionalInfo.Read',
  write: 'AdditionalInfo.Write',
  delete: 'AdditionalInfo.Delete',
};

const site = {
  navigation: 'SiteNavigation.Enable',
  feature: 'SiteManagementFeature.Enable',
  read: 'Site.Read',
  write: 'Site.Write',
  delete: 'Site.Delete',
  viewAll: 'SiteViewAll.Read',
};

const employmentEarning = {
  navigation: 'EmploymentEarningNavigation.Enable',
  read: 'EmploymentEarning.Read',
  write: 'EmploymentEarning.Write',
  delete: 'EmploymentEarning.Delete',
};

const cecContact = {
  navigation: 'CECContactNavigation.Enable',
  read: 'CECContact.Read',
  write: 'CECContact.Write',
  delete: 'CECContact.Delete',
};

const cecListing = {
  navigation: 'CECListNavigation.Enable',
  read: 'CECListing.Read',
  write: 'CECListing.Write',
  delete: 'CECListing.Delete',
};

const cecTag = {
  read: 'CECTag.Read',
  write: 'CECTag.Write',
  delete: 'CECTag.Delete',
};

const yjcContract = {
  navigation: 'YJCContractNavigation.Enable',
  read: 'YJCContract.Read',
  write: 'YJCContract.Write',
  delete: 'YJCContract.Delete',
  export: 'YJCContract.Export',
};

const yjcCheque = {
  tab: 'YJCChequeTab.Enable',
  read: 'YJCCheque.Read',
  write: 'YJCCheque.Write',
  delete: 'YJCCheque.Delete',
};
const yjcsContract = {
  navigation: 'YJCSContractNavigation.Enable',
  read: 'YJCSContract.Read',
  write: 'YJCSContract.Write',
  delete: 'YJCSContract.Delete',
  export: 'YJCSContract.Export',
};

const yjcsCheque = {
  tab: 'YJCSChequeTab.Enable',
  read: 'YJCSCheque.Read',
  write: 'YJCSCheque.Write',
  delete: 'YJCSCheque.Delete',
};

const alert = {
  navigation: 'AlertNavigation.Enable',
  read: 'Alert.Read',
  write: 'Alert.Write',
  delete: 'Alert.Delete',
};

const referral = {
  navigation: 'ReferralNavigation.Enable',
  read: 'Referral.Read',
  write: 'Referral.Write',
  delete: 'Referral.Delete',
};

const prospectReceiveNotification = {
  read: 'ProspectReceiveNotification.Read',
  write: 'ProspectReceiveNotification.Write',
  delete: 'ProspectReceiveNotification.Delete',
};

const dashboard = {
  navigation: 'DashboardNavigation.Enable',
};

const jobBoard = {
  navigation: 'JobBoardNavigation.Enable',
  read: 'JobBoard.Read',
  write: 'JobBoard.Write',
  delete: 'JobBoard.Delete',
};

const companyInfo = {
  navigation: 'CompanyInfoNavigation.Enable',
  read: 'CompanyInfo.Read',
  write: 'CompanyInfo.Write',
  delete: 'CompanyInfo.Delete',
};

export const permission = {
  user,
  role,
  additionalInfo,
  client,
  goClient,
  esClient,
  lbsClient,
  yjcClient,
  yjcsClient,
  cojgClient,
  seClient,
  odspClient,
  yessClient,
  ariClient,
  hubClient,
  saoClient,
  owepClient,
  iesClient,
  assessment,
  clientServicePlan,
  actionPlan,
  caseNote,
  clientFund,
  clientDocument,
  eventCalendar,
  mileageAndExpense,
  mileage,
  expense,
  mileageAndExpenseReport,
  cojgContract,
  cojgCheque,
  cojgCaseNote,
  esContract,
  esCheque,
  esCaseNote,
  seContract,
  seCheque,
  seCaseNote,
  budget,
  workshop,
  workshopEvent,
  eventParticipant,
  clientAggregateReport,
  clientReport,
  clientErrorReport,
  supportPayment,
  jobTrackingReport,
  clientFundReport,
  iesClientSupportReport,
  iesContractReport,
  performanceIndicatorIntake,
  cojgFinancialReport,
  contractsPaidReport,
  cojgFundReport,
  cojgReconciliationReport,
  esContractReport,
  esContractOutstandingReport,
  seContractReport,
  systemConfiguration,
  geography,
  jobType,
  shifts,
  sector,
  aggregate,
  mileageSupervisor,
  clientFundSupervisor,
  employer,
  employerContact,
  employerCaseNote,
  trainer,
  posting,
  camsImport,
  todo,
  formDocument,
  site,
  employmentEarning,
  workshopReport,
  cecContact,
  cecListing,
  cecTag,
  yjcContract,
  yjcCheque,
  yjcsContract,
  yjcsCheque,
  prospect,
  alert,
  referral,
  prospectReceiveNotification,
  dashboard,
  unassistedClient,
  jobBoard,
  companyInfo,
};
