import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import trainerSagas from './employers/saga';
import employerSagas from './employers/employer-saga';
import contactSagas from './contact/saga';
import clientSagas from './client/saga';
import actionPlanSaga from './action-plan/saga';
import activitySaga from './activity/saga';
import eventSaga from './event/saga';
import jobSeekerSaga from './job-seeker/saga';
import geographySagas from './geography/saga';
import shiftsSagas from './shifts/saga';
import jobtypeSagas from './job-type/saga';
import sectorSagas from './sector/saga';
import roleSagas from './role/saga';
import userSagas from './user/saga';
import assessementSaga from './assessement/saga';
import clientAggregateSaga from './client-aggregate/saga';
import aggregateSaga from './aggregate/saga';
import clientFundSaga from './client-fund/saga';
import caseNote2Saga from './case-note2/saga';
import resumeSaga from './resume/saga';
import camsImportSaga from './cams-import/saga';
import employerCaseNoteSaga from './employer-case-note/saga';
import todoSagas from './todo/saga';
import caseSummarySaga from './case-summary/saga';

import clientFundSettingSaga from './client-fund-setting/saga';
import cojgCaseNoteSaga from './cojg-case-note/saga';
import seContractSaga from './se-contract/saga';
import esContractSaga from './es-contract/saga';
import iesContractSaga from './ies-contract/saga';
import documentSaga from './document/saga';
import settingSaga from './system-setting/saga';
import esChequeSaga from './es-cheque/saga';
import seChequeSaga from './se-cheque/saga';
import esCaseNoteSaga from './es-case-note/saga';
import seCaseNoteSaga from './se-case-note/saga';
import eventCalendarSaga from './event-calendar/saga';
import formDocumentSaga from './form-document/saga';
import additionalInfoSaga from './additional-info/saga';
import siteSaga from './site/saga';
import employmentEarningSaga from './employment-earning/saga';
import postingSaga from './posting/saga';
import eventParticiapntSaga from './event-participant/saga';
import servicePlanSaga from './service-plan/saga';
import alertSaga from './alert/saga';
import referralSaga from './referral/saga';
import clientFundSupervisorSaga from './client-fund-supervisor/saga';
import employmentActionPlanSaga from './employment-action-plan/saga';
import clientSupportSaga from './client-support/saga';
import employerDocumentSaga from './employer-document/saga';
import outreachSaga from './outreach/saga';
import outcomesSaga from './outcomes/saga';
import subGoalSaga from './sub-goal/saga';
import clientSummarySaga from './client-summary/saga';
import jobMatchingSaga from './job-matching/saga';

import eidmSaga from './eidm/saga';
import subGoalLBSSaga from './subgoal-lbs/saga';
import clientSummaryLBSSaga from './client-summary-lbs/saga';
import clientTSASaga from './client-tsa/saga';
import ClientReferralSaga from './client-referral/saga';
import ClientCourseSaga from './client-course/saga';
import planContentSaga from './plan-content/saga';
import cojgContractDocumentSaga from './cojg-contract-document/saga';
import clientSummaryYJCSaga from './client-summary-yjc/saga';
import applicantSaga from './applicant/saga';
import caseAuditSaga from './case-audit/saga';
import clientInfoSaga from './client-info/saga';
import eapSaga from './eap/saga';
import caReferralSaga from './ca-referral/saga';
import employerSaga from './employer-info/saga';
import clientJobMatchSaga from './client-job-match/saga';
import clientNewMatchingSaga from './client-new-matching/saga';
import postingSentToClientSaga from './posting-sent-to-client/saga';
import postingNewMatchingSaga from './posting-new-matching/saga';
import postingMatchingSentToClientSaga from './posting-matching-sent-to-client/saga';
import taskManagementSaga from './task-management/saga';
import noteSaga from './note/saga';
import jobBoardSaga from './job-board/saga';
import transactionNotificationSaga from './transaction-notification/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    trainerSagas(),
    employerSagas(),
    contactSagas(),
    clientSagas(),
    actionPlanSaga(),
    activitySaga(),
    eventSaga(),
    jobSeekerSaga(),
    esContractSaga(),
    iesContractSaga(),
    geographySagas(),
    shiftsSagas(),
    jobtypeSagas(),
    sectorSagas(),
    roleSagas(),
    userSagas(),
    assessementSaga(),
    clientAggregateSaga(),
    aggregateSaga(),
    clientFundSaga(),
    caseNote2Saga(),
    resumeSaga(),
    camsImportSaga(),
    employerCaseNoteSaga(),
    todoSagas(),
    caseSummarySaga(),
    clientFundSettingSaga(),
    cojgCaseNoteSaga(),
    seContractSaga(),
    documentSaga(),
    settingSaga(),
    esChequeSaga(),
    seChequeSaga(),
    esCaseNoteSaga(),
    seCaseNoteSaga(),
    eventCalendarSaga(),
    formDocumentSaga(),
    additionalInfoSaga(),
    siteSaga(),
    employmentEarningSaga(),
    postingSaga(),
    eventParticiapntSaga(),
    servicePlanSaga(),
    alertSaga(),
    referralSaga(),
    clientFundSupervisorSaga(),
    employmentActionPlanSaga(),
    clientSupportSaga(),
    employerDocumentSaga(),
    outreachSaga(),
    outcomesSaga(),
    subGoalSaga(),
    clientSummarySaga(),
    jobMatchingSaga(),
    eidmSaga(),
    subGoalLBSSaga(),
    clientSummaryLBSSaga(),
    clientTSASaga(),
    ClientReferralSaga(),
    ClientCourseSaga(),
    planContentSaga(),
    cojgContractDocumentSaga(),
    clientSummaryYJCSaga(),
    applicantSaga(),
    caseAuditSaga(),
    clientJobMatchSaga(),
    clientNewMatchingSaga(),
    postingSentToClientSaga(),
    postingNewMatchingSaga(),
    postingMatchingSentToClientSaga(),
    noteSaga(),
    taskManagementSaga(),
    clientInfoSaga(),
    eapSaga(),
    caReferralSaga(),
    employerSaga(),
    jobBoardSaga(),
    transactionNotificationSaga(),
  ]);
}
