// JOB_BOARD
export const JOB_BOARD_GET_BY_ID = 'JOB_BOARD_GET_BY_ID';
export const JOB_BOARD_GET_BY_ID_SUCCESS = 'JOB_BOARD_GET_BY_ID_SUCCESS';
export const JOB_BOARD_GET_BY_ID_ERROR = 'JOB_BOARD_GET_BY_ID_ERROR';

export const JOB_BOARD_GET_LIST = 'JOB_BOARD_GET_LIST';
export const JOB_BOARD_GET_LIST_SUCCESS = 'JOB_BOARD_GET_LIST_SUCCESS';
export const JOB_BOARD_GET_LIST_ERROR = 'JOB_BOARD_GET_LIST_ERROR';

export const JOB_BOARD_EDIT = 'JOB_BOARD_EDIT';
export const JOB_BOARD_EDIT_SUCCESS = 'JOB_BOARD_EDIT_SUCCESS';
export const JOB_BOARD_EDIT_ERROR = 'JOB_BOARD_EDIT_ERROR';

export const JOB_BOARD_AUTHORIZE = 'JOB_BOARD_AUTHORIZE';
export const JOB_BOARD_AUTHORIZE_SUCCESS = 'JOB_BOARD_AUTHORIZE_SUCCESS';
export const JOB_BOARD_AUTHORIZE_ERROR = 'JOB_BOARD_AUTHORIZE_ERROR';

export const JOB_BOARD_ADD_NEW = 'JOB_BOARD_ADD_NEW';
export const JOB_BOARD_ADD_NEW_SUCCESS = 'JOB_BOARD_ADD_NEW_SUCCESS';
export const JOB_BOARD_ADD_NEW_ERROR = 'JOB_BOARD_ADD_NEW_ERROR';
export const JOB_BOARD_ADD_NEW_REQUEST = 'JOB_BOARD_ADD_NEW_REQUEST';

export const JOB_BOARD_DELETE = 'JOB_BOARD_DELETE';
export const JOB_BOARD_DELETE_SUCCESS = 'JOB_BOARD_DELETE_SUCCESS';
export const JOB_BOARD_DELETE_ERROR = 'JOB_BOARD_DELETE_ERROR';

export const JOB_BOARD_GET_BY_QUERY = 'JOB_BOARD_GET_BY_QUERY';
export const JOB_BOARD_GET_BY_QUERY_SUCCESS = 'JOB_BOARD_GET_BY_QUERY_SUCCESS';
export const JOB_BOARD_GET_BY_QUERY_ERROR = 'JOB_BOARD_GET_BY_QUERY_ERROR';

export const CLEAR_JOB_BOARD_MESSAGE = 'CLEAR_JOB_BOARD_MESSAGE';
