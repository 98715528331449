import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import { JOB_BOARD_GET_BY_QUERY, JOB_BOARD_AUTHORIZE } from './types';

import {
  getJobBoardByQuerySuccess,
  getJobBoardByQueryError,
  getJobBoardAuthorizeSuccess,
  getJobBoardAuthorizeError,
} from './actions';
import { getTransactionNotificationByQueryRequest } from '../transaction-notification/saga';
import { getTransactionNotificationByQuerySuccess } from '../actions';

const getJobBoardAuthorizeRequest = async (jobBoardId, data) => {
  return axios.post(`${Config.apiServerHost}/api/JobBoard/${jobBoardId}/authorize`, data, {
    headers: { ...(await authHeader()).headers },
  });
};

const getJobBoardByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/JobBoard/fetch`;
  return await axios.get(url, { params: query, headers: { ...(await authHeader()).headers } });
};

function* getJobBoardAuthorize({ payload }) {
  try {
    const { jobBoardId, data } = payload;
    const response = yield call(getJobBoardAuthorizeRequest, jobBoardId, data);
    yield put(getJobBoardByQuerySuccess(response.data));
    yield put(getJobBoardAuthorizeSuccess(response.data));

    const secondResponse = yield call(getTransactionNotificationByQueryRequest);
    yield put(getTransactionNotificationByQuerySuccess(secondResponse.data));
  } catch (error) {
    yield put(getJobBoardAuthorizeError(error.response.statusText));
  }
}

function* getJobBoardByQuery({ payload }) {
  try {
    const response = yield call(getJobBoardByQueryRequest, payload);
    yield put(getJobBoardByQuerySuccess(response.data));

    const secondResponse = yield call(getTransactionNotificationByQueryRequest);
    yield put(getTransactionNotificationByQuerySuccess(secondResponse.data));
  } catch (error) {
    yield put(getJobBoardByQueryError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(JOB_BOARD_GET_BY_QUERY, getJobBoardByQuery);
  yield takeEvery(JOB_BOARD_AUTHORIZE, getJobBoardAuthorize);
}
