import {
  CLIENT_GET_LIST,
  CLIENT_GET_LIST_SUCCESS,
  CLIENT_GET_LIST_ERROR,
  CLIENT_GET_BY_ID,
  CLIENT_GET_BY_ID_SUCCESS,
  CLIENT_GET_BY_ID_ERROR,
  CLIENT_ADD_NEW,
  CLIENT_ADD_NEW_SUCCESS,
  CLIENT_ADD_NEW_ERROR,
  CLIENT_EDIT,
  CLIENT_EDIT_SUCCESS,
  CLIENT_EDIT_ERROR,
  CLIENT_DELETE,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_ERROR,
  CLIENT_DELETE_REQUEST,
  CLIENT_FILTER,
  CLIENT_FILTER_SUCCESS,
  CLIENT_FILTER_ERROR,
  NOTIFICATION_GET_LIST_SUCCESS,
  CLIENT_MANAGER_VIEW_UPDATE,
  CLIENT_COMPLETED_RESULT,
  GENERATE_CLIENT_REPORT,
  GENERATE_CLIENT_REPORT_SUCCESS,
  GENERATE_CLIENT_REPORT_ERROR,
  CLIENT_FILE_ACIVITY_UPDATE,
  CLIENT_FILE_ACIVITY_UPDATE_SUCCESS,
  CLIENT_FILE_ACIVITY_UPDATE_ERROR,
  CLIENT_FILE_ACIVITY_HUB_UPDATE,
  CLIENT_FILE_ACIVITY_HUB_UPDATE_SUCCESS,
  CLIENT_FILE_ACIVITY_HUB_UPDATE_ERROR,
  GET_CLIENT_FILE_ACIVITY,
  GET_CLIENT_FILE_ACIVITY_SUCCESS,
  GET_CLIENT_FILE_ACIVITY_ERROR,
  GET_CLIENT_FILE_ACIVITY_HUB,
  GET_CLIENT_FILE_ACIVITY_HUB_SUCCESS,
  GET_CLIENT_FILE_ACIVITY_HUB_ERROR,
  ADD_NEW_CLIENT_PARTICIPANT,
  SAVE_CLIENT_PARTICIPANT,
  SAVE_CLIENT_PARTICIPANT_SUCCESS,
  SAVE_CLIENT_PARTICIPANT_ERROR,
  SAVE_CLIENT_PARTICIPANT_ARI,
  SAVE_CLIENT_PARTICIPANT_ARI_SUCCESS,
  SAVE_CLIENT_PARTICIPANT_ARI_ERROR,
  SAVE_CLIENT_PARTICIPANT_HUB,
  SAVE_CLIENT_PARTICIPANT_HUB_SUCCESS,
  SAVE_CLIENT_PARTICIPANT_HUB_ERROR,
  SAVE_CLIENT_PARTICIPANT_LBS,
  SAVE_CLIENT_PARTICIPANT_LBS_SUCCESS,
  SAVE_CLIENT_PARTICIPANT_LBS_ERROR,
  GET_CLIENT_PARTICIPANT,
  GET_CLIENT_PARTICIPANT_SUCCESS,
  GET_CLIENT_PARTICIPANT_ERROR,
  GET_CLIENT_SERVICEPLANS,
  GET_CLIENT_SERVICEPLANS_SUCCESS,
  GET_CLIENT_SERVICEPLANS_ERROR,
  GET_CLIENT_PARTICIPANT_HUB,
  GET_CLIENT_PARTICIPANT_HUB_SUCCESS,
  GET_CLIENT_PARTICIPANT_HUB_ERROR,
  GET_CLIENT_PARTICIPANT_ARI,
  GET_CLIENT_PARTICIPANT_ARI_SUCCESS,
  GET_CLIENT_PARTICIPANT_ARI_ERROR,
  GET_CLIENT_PARTICIPANT_LBS,
  GET_CLIENT_PARTICIPANT_LBS_SUCCESS,
  GET_CLIENT_PARTICIPANT_LBS_ERROR,
  SAVE_CLIENT_ASSESSMENT,
  SAVE_CLIENT_ASSESSMENT_SUCCESS,
  SAVE_CLIENT_ASSESSMENT_ERROR,
  SAVE_CLIENT_SPECIFIC_ASSESSMENT,
  SAVE_CLIENT_SPECIFIC_ASSESSMENT_SUCCESS,
  SAVE_CLIENT_SPECIFIC_ASSESSMENT_ERROR,
  GET_CLIENT_ASSESSMENT,
  GET_CLIENT_ASSESSMENT_SUCCESS,
  GET_CLIENT_ASSESSMENT_ERROR,
  GET_CLIENT_SPECIFIC_ASSESSMENT,
  GET_CLIENT_SPECIFIC_ASSESSMENT_SUCCESS,
  GET_CLIENT_SPECIFIC_ASSESSMENT_ERROR,
  CLIENT_CLOSED_NOTIFICATION_UPDATE,
  CLIENT_ERROR_REPORT,
  CLIENT_ERROR_REPORT_SUCCESS,
  CLIENT_ERROR_REPORT_ERROR,
  CLIENT_CLOSED_NOTIFICATION_ERROR,
  UNSUBSCRIBED_CLIENT_DATA,
  GENERATE_PERFORMANCE_INDICATOR_REPORT,
  GENERATE_PERFORMANCE_INDICATOR_REPORT_SUCCESS,
  GENERATE_PERFORMANCE_INDICATOR_REPORT_ERROR,
  CLIENT_SAVE_LEARNER_PLAN,
  CLIENT_SAVE_LEARNER_PLAN_SUCCESS,
  CLIENT_SAVE_LEARNER_PLAN_ERROR,
  CLIENT_GET_LEARNER_PLAN,
  CLIENT_GET_LEARNER_PLAN_SUCCESS,
  CLIENT_GET_LEARNER_PLAN_ERROR,
  GET_STUDENT_CHECKLIST_LBS,
  GET_STUDENT_CHECKLIST_LBS_SUCCESS,
  GET_STUDENT_CHECKLIST_LBS_ERROR,
  SAVE_STUDENT_CHECKLIST_LBS,
  SAVE_STUDENT_CHECKLIST_LBS_ERROR,
  SAVE_STUDENT_CHECKLIST_LBS_SUCCESS,
  ADD_CLIENT_SERVICEPLANS,
  ADD_CLIENT_SERVICEPLANS_SUCCESS,
  ADD_CLIENT_SERVICEPLANS_ERROR,
  UPDATE_CLIENT_SERVICEPLAN,
  UPDATE_CLIENT_SERVICEPLAN_SUCCESS,
  UPDATE_CLIENT_SERVICEPLAN_ERROR,
  GET_CLIENT_SERVICEPLAN,
  GET_CLIENT_SERVICEPLAN_SUCCESS,
  GET_CLIENT_SERVICEPLAN_ERROR,
  SAVE_CLIENT_ASSESSMENT_LBS,
  SAVE_CLIENT_ASSESSMENT_LBS_SUCCESS,
  SAVE_CLIENT_ASSESSMENT_LBS_ERROR,
  GET_CLIENT_ASSESSMENT_LBS,
  GET_CLIENT_ASSESSMENT_LBS_SUCCESS,
  GET_CLIENT_ASSESSMENT_LBS_ERROR,
  GET_CLIENT_KPI,
  GET_CLIENT_KPI_SUCCESS,
  GET_CLIENT_LEFT_SIDEBAR,
  GET_CLIENT_LEFT_SIDEBAR_SUCCESS,
  GET_CLIENT_LEFT_SIDEBAR_ERROR,
  SAVE_CLIENT_COMMENT,
} from '../actions';

const IntialState = {
  clients: [],
  client: null,
  clientFileActivity: null,
  clientParticipant: null,
  clientAssessment: null,
  notifications: [],
  reportData: null,
  loading: false,
  error: null,
  showNotification: false,
  titleNotification: '',
  deleteSuccess: false,
  performance: null,
  learnerPlan: null,
  jobMatching: [],
  studentChecklist: null,
  clientServicePlans: null,
  clientServicePlan: null,

  itemCount: 0,
  pageCount: 0,
  totalActiveOnHoldServicePlans: 0,
  totalActiveOutcomesServicePlans: 0,
  totalUnassignedServicePlans: 0,
  totalActiveServicePlans: 0,
  totalCloseServicePlans: 0,
  totalIRServicePlans: 0,
  totalOpenServicePlans: 0,
  clientKPI: null,
  totalMyClient: 0,
  totalAllClient: 0,
  leftSidebar: null,
  leftSidebarLoading: false,
};

export default function reducerState(state = IntialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLIENT_CLOSED_NOTIFICATION_ERROR:
      return {
        ...state,
        error: null,
      };

    case CLIENT_FILTER:
    case CLIENT_GET_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CLIENT_FILTER_SUCCESS:
    case CLIENT_GET_LIST_SUCCESS:
      const {
        itemCount,
        pageCount,
        data,
        totalActiveOnHoldServicePlans,
        totalActiveOutcomesServicePlans,
        totalActiveServicePlans,
        totalUnassignedServicePlans,
        totalCloseServicePlans,
        totalIRServicePlans,
        totalOpenServicePlans,
        totalMyClient,
        totalAllClient,
      } = payload;
      return {
        ...state,
        loading: false,
        clients: data,
        itemCount,
        pageCount,
        totalActiveOnHoldServicePlans,
        totalActiveOutcomesServicePlans,
        totalUnassignedServicePlans,
        totalActiveServicePlans,
        totalCloseServicePlans,
        totalIRServicePlans,
        totalOpenServicePlans,
        totalMyClient,
        totalAllClient,
        error: null,
      };
    case GET_CLIENT_LEFT_SIDEBAR:
    case SAVE_CLIENT_COMMENT:
      return {
        ...state,
        leftSidebarLoading: true,
        error: null,
      };

    case GET_CLIENT_KPI:
    case SAVE_CLIENT_ASSESSMENT_LBS:
    case GET_CLIENT_ASSESSMENT_LBS:
    case GET_CLIENT_FILE_ACIVITY:
    case GET_CLIENT_FILE_ACIVITY_HUB:
    case CLIENT_FILE_ACIVITY_UPDATE:
    case CLIENT_FILE_ACIVITY_HUB_UPDATE:
    case SAVE_CLIENT_PARTICIPANT:
    case SAVE_CLIENT_PARTICIPANT_ARI:
    case SAVE_CLIENT_PARTICIPANT_HUB:
    case SAVE_CLIENT_PARTICIPANT_LBS:
    case GET_CLIENT_PARTICIPANT:
    case GET_CLIENT_PARTICIPANT_HUB:
    case GET_CLIENT_PARTICIPANT_ARI:
    case GET_CLIENT_PARTICIPANT_LBS:
    case SAVE_CLIENT_ASSESSMENT:
    case SAVE_CLIENT_SPECIFIC_ASSESSMENT:
    case GET_CLIENT_ASSESSMENT:
    case GET_CLIENT_SPECIFIC_ASSESSMENT:
    case CLIENT_SAVE_LEARNER_PLAN:
    case CLIENT_GET_LEARNER_PLAN:
    case GET_STUDENT_CHECKLIST_LBS:
    case SAVE_STUDENT_CHECKLIST_LBS:
    case GET_CLIENT_SERVICEPLANS:
    case ADD_CLIENT_SERVICEPLANS:
    case GET_CLIENT_SERVICEPLAN:
    case UPDATE_CLIENT_SERVICEPLAN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CLIENT_LEFT_SIDEBAR_SUCCESS:
      return {
        ...state,
        leftSidebar: payload,
        leftSidebarLoading: false,
      };
    case GET_CLIENT_KPI_SUCCESS:
      return {
        ...state,
        clientKPI: payload,
        loading: false,
      };
    case GET_CLIENT_SERVICEPLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        clientServicePlan: payload,
        error: null,
      };
    case GET_CLIENT_SERVICEPLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientServicePlans: payload,
        error: null,
      };
    case GET_STUDENT_CHECKLIST_LBS_SUCCESS:
      return {
        ...state,
        loading: false,
        studentChecklist: payload,
        error: null,
      };
    case SAVE_STUDENT_CHECKLIST_LBS_SUCCESS:
      return {
        ...state,
        loading: false,
        studentChecklist: payload,
        showNotification: true,
        titleNotification: 'Student CheckList',
        error: null,
      };
    case CLIENT_FILE_ACIVITY_UPDATE_SUCCESS:
    case CLIENT_FILE_ACIVITY_HUB_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        clientFileActivity: payload,
        showNotification: true,
        titleNotification: 'File Activity',
        error: null,
      };

    case UNSUBSCRIBED_CLIENT_DATA:
      return {
        ...state,
        loading: false,
        clientFileActivity: null,
        clientParticipant: null,
        clientAssessment: null,
      };

    case GET_CLIENT_FILE_ACIVITY_SUCCESS:
    case GET_CLIENT_FILE_ACIVITY_HUB_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clientFileActivity: payload,
      };

    case CLIENT_CLOSED_NOTIFICATION_UPDATE:
      return {
        ...state,
        showNotification: false,
        titleNotification: '',
      };
    case ADD_NEW_CLIENT_PARTICIPANT:
      return {
        ...state,
        clientParticipant: null,
        loading: false,
        error: null,
        deleteSuccess: false,
      };

    case CLIENT_GET_LEARNER_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        learnerPlan: payload,
        error: null,
      };

    case CLIENT_SAVE_LEARNER_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        learnerPlan: payload,
        showNotification: true,
        titleNotification: 'Learner Plan',
        error: null,
      };

    case SAVE_CLIENT_PARTICIPANT_SUCCESS:
    case SAVE_CLIENT_PARTICIPANT_ARI_SUCCESS:
    case SAVE_CLIENT_PARTICIPANT_HUB_SUCCESS:
    case SAVE_CLIENT_PARTICIPANT_LBS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientParticipant: payload,
        showNotification: true,
        titleNotification: 'Participant Info',
        error: null,
      };
    case GET_CLIENT_PARTICIPANT_SUCCESS:
    case GET_CLIENT_PARTICIPANT_HUB_SUCCESS:
    case GET_CLIENT_PARTICIPANT_ARI_SUCCESS:
    case GET_CLIENT_PARTICIPANT_LBS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clientParticipant: payload,
      };

    case SAVE_CLIENT_ASSESSMENT_LBS_SUCCESS:
    case SAVE_CLIENT_SPECIFIC_ASSESSMENT_SUCCESS:
    case SAVE_CLIENT_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clientAssessment: payload,
        showNotification: true,
        titleNotification: 'Assessment',
      };

    case GET_CLIENT_ASSESSMENT_LBS_SUCCESS:
    case GET_CLIENT_SPECIFIC_ASSESSMENT_SUCCESS:
    case GET_CLIENT_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clientAssessment: payload,
      };
    case CLIENT_DELETE_SUCCESS:
      return {
        ...state,
        clients: state.clients.filter(a => a.id !== payload),
        loading: false,
        deleteSuccess: true,
      };

    case GET_CLIENT_ASSESSMENT_LBS_ERROR:
    case SAVE_CLIENT_ASSESSMENT_LBS_ERROR:
    case GENERATE_PERFORMANCE_INDICATOR_REPORT_ERROR:
    case CLIENT_EDIT_ERROR:
    case CLIENT_ADD_NEW_ERROR:
    case CLIENT_FILTER_ERROR:
    case CLIENT_GET_LIST_ERROR:
    case CLIENT_GET_BY_ID_ERROR:
    case GENERATE_CLIENT_REPORT_ERROR:
    case CLIENT_FILE_ACIVITY_UPDATE_ERROR:
    case CLIENT_FILE_ACIVITY_HUB_UPDATE_ERROR:
    case GET_CLIENT_FILE_ACIVITY_ERROR:
    case GET_CLIENT_FILE_ACIVITY_HUB_ERROR:
    case GET_CLIENT_PARTICIPANT_ERROR:
    case GET_CLIENT_PARTICIPANT_HUB_ERROR:
    case GET_CLIENT_PARTICIPANT_ARI_ERROR:
    case GET_CLIENT_PARTICIPANT_LBS_ERROR:
    case SAVE_CLIENT_ASSESSMENT_ERROR:
    case SAVE_CLIENT_SPECIFIC_ASSESSMENT_ERROR:
    case GET_CLIENT_ASSESSMENT_ERROR:
    case GET_CLIENT_SPECIFIC_ASSESSMENT_ERROR:
    case CLIENT_DELETE_ERROR:
    case CLIENT_ERROR_REPORT_ERROR:
    case SAVE_CLIENT_PARTICIPANT_ERROR:
    case SAVE_CLIENT_PARTICIPANT_ARI_ERROR:
    case SAVE_CLIENT_PARTICIPANT_HUB_ERROR:
    case SAVE_CLIENT_PARTICIPANT_LBS_ERROR:
    case CLIENT_SAVE_LEARNER_PLAN_ERROR:
    case CLIENT_GET_LEARNER_PLAN_ERROR:
    case GET_STUDENT_CHECKLIST_LBS_ERROR:
    case SAVE_STUDENT_CHECKLIST_LBS_ERROR:
    case GET_CLIENT_SERVICEPLANS_ERROR:
    case ADD_CLIENT_SERVICEPLANS_ERROR:
    case GET_CLIENT_SERVICEPLAN_ERROR:
    case UPDATE_CLIENT_SERVICEPLAN_ERROR:
    case GET_CLIENT_LEFT_SIDEBAR_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GENERATE_PERFORMANCE_INDICATOR_REPORT:
    case CLIENT_ERROR_REPORT:
    case GENERATE_CLIENT_REPORT:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GENERATE_PERFORMANCE_INDICATOR_REPORT_SUCCESS:
      return {
        ...state,
        performance: payload,
        loading: false,
        error: null,
      };
    case CLIENT_ERROR_REPORT_SUCCESS:
    case GENERATE_CLIENT_REPORT_SUCCESS:
      return {
        ...state,
        reportData: payload,
        loading: false,
        error: null,
      };

    case CLIENT_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
      };
    case CLIENT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        client: payload,
        loading: false,
        error: null,
      };

    case NOTIFICATION_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: payload,
        addSuccess: true,
      };

    case CLIENT_ADD_NEW:
      return {
        ...state,
        client: payload,
        deleteSuccess: false,
      };
    case CLIENT_ADD_NEW_SUCCESS:
      return {
        ...state,
        client: payload,
        error: null,
      };

    case CLIENT_COMPLETED_RESULT:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_MANAGER_VIEW_UPDATE:
      return {
        ...state,
        client: payload.client,
      };

    case CLIENT_EDIT:
      return {
        ...state,
        client: payload.client,
        deleteSuccess: false,
      };
    case CLIENT_EDIT_SUCCESS:
      return {
        ...state,
        client: payload,
        loading: false,
        error: null,
      };
    case CLIENT_DELETE:
      return {
        ...state,
        client: payload,
        loading: true,
        error: null,
        deleteSuccess: false,
      };
    case CLIENT_DELETE_REQUEST:
      return {
        ...state,
        deleteSuccess: false,
      };
    default:
      return {
        ...state,
      };
  }
}
