import {
  CLIENT_INFO_GET_BY_ID,
  CLIENT_INFO_GET_BY_ID_ERROR,
  CLIENT_INFO_GET_BY_ID_SUCCESS,
  CLIENT_INFO_UPDATE,
  CLIENT_INFO_UPDATE_SUCCESS,
  CLIENT_INFO_UPDATE_ERROR,
  CLIENT_INFO_SYNC,
  CLIENT_INFO_SYNC_SUCCESS,
  CLIENT_INFO_SYNC_ERROR,
} from './types';

export const getClientInfoById = id => ({
  type: CLIENT_INFO_GET_BY_ID,
  payload: id,
});

export const getClientInfoByIdSuccess = clientInfo => ({
  type: CLIENT_INFO_GET_BY_ID_SUCCESS,
  payload: clientInfo,
});

export const getClientInfoByIdError = error => ({
  type: CLIENT_INFO_GET_BY_ID_ERROR,
  payload: error,
});

export const updateClientInfo = clientInfo => ({
  type: CLIENT_INFO_UPDATE,
  payload: clientInfo,
});

export const updateClientInfoSuccess = clientInfo => ({
  type: CLIENT_INFO_UPDATE_SUCCESS,
  payload: clientInfo,
});

export const updateClientInfoError = error => ({
  type: CLIENT_INFO_UPDATE_ERROR,
  payload: error,
});

export const syncClientInfo = (clientId, servicePlanHeaderId, clientReferenceNumberOverride) => ({
  type: CLIENT_INFO_SYNC,
  payload: { clientId, servicePlanHeaderId, clientReferenceNumberOverride },
});

export const syncClientInfoSuccess = clientInfo => ({
  type: CLIENT_INFO_SYNC_SUCCESS,
  payload: clientInfo,
});

export const syncClientInfoError = error => ({
  type: CLIENT_INFO_SYNC_ERROR,
  payload: error,
});
