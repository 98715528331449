import {
  JOB_BOARD_ADD_NEW,
  JOB_BOARD_ADD_NEW_SUCCESS,
  JOB_BOARD_ADD_NEW_ERROR,
  JOB_BOARD_EDIT,
  JOB_BOARD_EDIT_ERROR,
  JOB_BOARD_EDIT_SUCCESS,
  JOB_BOARD_GET_BY_QUERY,
  JOB_BOARD_GET_BY_ID,
  JOB_BOARD_GET_BY_QUERY_SUCCESS,
  JOB_BOARD_GET_BY_QUERY_ERROR,
  JOB_BOARD_GET_BY_ID_SUCCESS,
  JOB_BOARD_ADD_NEW_REQUEST,
  JOB_BOARD_DELETE,
  JOB_BOARD_DELETE_SUCCESS,
  JOB_BOARD_DELETE_ERROR,
  JOB_BOARD_GET_BY_ID_ERROR,
  JOB_BOARD_AUTHORIZE,
  JOB_BOARD_AUTHORIZE_SUCCESS,
  JOB_BOARD_AUTHORIZE_ERROR,
  CLEAR_JOB_BOARD_MESSAGE,
} from './types';

// JobBoard

export const addNewJobBoard = items => ({
  type: JOB_BOARD_ADD_NEW,
  payload: items,
});

export const addNewJobBoardRequest = () => ({
  type: JOB_BOARD_ADD_NEW_REQUEST,
});

export const addNewJobBoardSuccess = items => ({
  type: JOB_BOARD_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewJobBoardError = error => ({
  type: JOB_BOARD_ADD_NEW_ERROR,
  payload: error,
});

export const editJobBoard = item => ({
  type: JOB_BOARD_EDIT,
  payload: item,
});

export const editJobBoardSuccess = item => ({
  type: JOB_BOARD_EDIT_SUCCESS,
  payload: item,
});

export const editJobBoardError = error => ({
  type: JOB_BOARD_EDIT_ERROR,
  payload: error,
});

export const getJobBoardByQuery = query => ({
  type: JOB_BOARD_GET_BY_QUERY,
  payload: query,
});

export const getJobBoardByQuerySuccess = item => ({
  type: JOB_BOARD_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getJobBoardByQueryError = error => ({
  type: JOB_BOARD_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getJobBoardAuthorize = query => ({
  type: JOB_BOARD_AUTHORIZE,
  payload: query,
});

export const getJobBoardAuthorizeSuccess = item => ({
  type: JOB_BOARD_AUTHORIZE_SUCCESS,
  payload: item,
});

export const getJobBoardAuthorizeError = error => ({
  type: JOB_BOARD_AUTHORIZE_ERROR,
  payload: error,
});

export const getJobBoardById = id => ({
  type: JOB_BOARD_GET_BY_ID,
  payload: id,
});

export const getJobBoardByIdSuccess = item => ({
  type: JOB_BOARD_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getJobBoardByIdError = error => ({
  type: JOB_BOARD_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteJobBoardById = id => ({
  type: JOB_BOARD_DELETE,
  payload: id,
});

export const deleteJobBoardByIdSuccess = item => ({
  type: JOB_BOARD_DELETE_SUCCESS,
  payload: item,
});

export const deleteJobBoardByIdErrorr = item => ({
  type: JOB_BOARD_DELETE_ERROR,
  payload: item,
});

export const clearJobBoardMessage = item => ({
  type: CLEAR_JOB_BOARD_MESSAGE,
  payload: item,
});
