import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import trainerApp from './employers/reducer';
import employer from './employers/employer-reducer';
import client from './client/reducer';
import actionPlan from './action-plan/reducer';
import activity from './activity/reducer';
import event from './event/reducer';
import jobSeeker from './job-seeker/reducer';

import geography from './geography/reducer';
import shift from './shifts/reducer';
import jobtype from './job-type/reducer';
import sector from './sector/reducer';
import role from './role/reducer';
import userList from './user/reducer';
import assessement from './assessement/reducer';
import clientAggregate from './client-aggregate/reducer';
import aggregate from './aggregate/reducer';
import clientFund from './client-fund/reducer';
import caseNote2 from './case-note2/reducer';
import resume from './resume/reducer';
import camsImport from './cams-import/reducer';
import employerCaseNote from './employer-case-note/reducer';
import todoApp from './todo/reducer';
import caseSummary from './case-summary/reducer';

import clientFundSetting from './client-fund-setting/reducer';
import cojgCaseNote from './cojg-case-note/reducer';
import document from './document/reducer';
import setting from './system-setting/reducer';
import eventCalendar from './event-calendar/reducer';
import formDocument from './form-document/reducer';
import additionalInfo from './additional-info/reducer';
import site from './site/reducer';
import employmentEarning from './employment-earning/reducer';
import contact from './contact/reducer';
import posting from './posting/reducer';
import eventParticipant from './event-participant/reducer';
import cecContact from './cec-contact/reducer';
import prospectReceiveNotification from './prospect-receive-notification/reducer';
import servicePlan from './service-plan/reducer';
import alert from './alert/reducer';
import referral from './referral/reducer';
import clientFundSupervisor from './client-fund-supervisor/reducer';
import employmentActionPlan from './employment-action-plan/reducer';
import clientSupport from './client-support/reducer';
import employerDocument from './employer-document/reducer';
import outreach from './outreach/reducer';
import outcomes from './outcomes/reducer';
import subGoal from './sub-goal/reducer';
import clientSummary from './client-summary/reducer';
import jobMatching from './job-matching/reducer';
import eidm from './eidm/reducer';
import subGoalLBS from './subgoal-lbs/reducer';
import clientSummaryLBS from './client-summary-lbs/reducer';
import clientTSA from './client-tsa/reducer';
import ClientReferral from './client-referral/reducer';
import planContent from './plan-content/reducer';
import cojgContractDocument from './cojg-contract-document/reducer';
import clientCourse from './client-course/reducer';
import clientSummaryYJC from './client-summary-yjc/reducer';
import applicant from './applicant/reducer';
import caseAudit from './case-audit/reducer';
import clientInfo from './client-info/reducer';
import eap from './eap/reducer';
import caReferral from './ca-referral/reducer';
import employerInfo from './employer-info/reducer';
import clientJobMatch from './client-job-match/reducer';
import clientNewMatching from './client-new-matching/reducer';
import postingSentToClient from './posting-sent-to-client/reducer';
import postingNewMatching from './posting-new-matching/reducer';
import postingMatchingSentToClient from './posting-matching-sent-to-client/reducer';
import taskManagement from './task-management/reducer';
import note from './note/reducer';
import jobBoard from './job-board/reducer';
import transactionNotification from './transaction-notification/reducer';
const reducers = combineReducers({
  menu,
  settings,
  authUser,
  trainerApp,
  employer,
  contact,
  client,
  actionPlan,
  activity,
  event,
  jobSeeker,
  geography,
  shift,
  jobtype,
  sector,
  role,
  userList,
  assessement,
  clientAggregate,
  aggregate,
  clientFund,
  caseNote2,
  resume,
  camsImport,
  employerCaseNote,
  todoApp,
  caseSummary,
  clientFundSetting,
  cojgCaseNote,
  document,
  setting,

  eventCalendar,
  formDocument,
  additionalInfo,
  site,
  employmentEarning,
  posting,
  eventParticipant,
  cecContact,

  prospectReceiveNotification,
  servicePlan,
  alert,
  referral,
  clientFundSupervisor,
  employmentActionPlan,
  clientSupport,
  employerDocument,
  outreach,
  outcomes,
  subGoal,
  clientSummary,

  jobMatching,
  eidm,
  subGoalLBS,
  clientSummaryLBS,
  clientTSA,
  ClientReferral,
  clientCourse,
  planContent,
  cojgContractDocument,
  clientSummaryYJC,
  applicant,
  caseAudit,
  clientInfo,
  eap,
  caReferral,
  employerInfo,
  clientJobMatch,
  clientNewMatching,
  postingSentToClient,
  postingNewMatching,
  postingMatchingSentToClient,
  note,
  taskManagement,
  jobBoard,
  transactionNotification,
});

export default reducers;
