import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-table-6/react-table.css';
import './assets/css/CustomStyleSheet.css';

import {
  isMultiColorActive,
  defaultColor,
  themeColorStorageKey,
  isDarkSwitchActive,
} from './constants/defaultValues';
import { getCompanyName } from './helpers/Utils';
const color =
  (isMultiColorActive || isDarkSwitchActive) && localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

let render = () => {
  let company = getCompanyName();
  console.log(company);
  switch (company) {
    case 'keys':
      import('./assets/css/sass/themes/keys.scss').then(x => {
        require('./AppRenderer');
      });
      break;
    case 'fedcap':
      import('./assets/css/sass/themes/fedcap.scss').then(x => {
        require('./AppRenderer');
      });
      break;
    default:
      import('./assets/css/sass/themes/gogo.' + color + '.scss').then(x => {
        require('./AppRenderer');
      });
      break;
  }
};
render();
