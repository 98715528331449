import { SEVERITY } from '../../helpers/Utils';
import {
  POSTING_ADD_NEW,
  POSTING_DELETE,
  POSTING_EDIT,
  POSTING_GET_BY_ID,
  POSTING_GET_BY_ID_SUCCESS,
  POSTING_GET_LIST,
  POSTING_GET_LIST_SUCCESS,
  POSTING_ADD_NEW_REQUEST,
  POSTING_RESULT_CHANGE,
  POSTING_ERROR,
  POSTING_EDIT_SUCCESS,
  CLEAR_POSTING_MESSAGE,
  POSTING_ADD_NEW_SUCCESS,
  POSTING_PUBLISH_SUCCESS,
  POSTING_PUBLISH,
} from './types';

const initialState = {
  postings: [],
  posting: null,
  notifications: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  totalActive: 0,
  totalDraft: 0,
  totalClosed: 0,
  message: null,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case POSTING_RESULT_CHANGE:
    case POSTING_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        posting: null,
      };
    case POSTING_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        posting: payload,
      };

    case POSTING_ADD_NEW_REQUEST:
      return {
        ...state,
        posting: null,
      };
    case POSTING_GET_LIST:
      return {
        ...state,
        error: null,
        loading: true,
        posting: null,
      };
    case POSTING_GET_LIST_SUCCESS:
      const { itemCount, pageCount, data, totalActive, totalDraft, totalClosed } = payload;
      return {
        ...state,
        postings: data,
        loading: false,
        posting: null,
        itemCount,
        pageCount,
        removeSuccess: true,
        totalActive,
        totalDraft,
        totalClosed,
      };
    case POSTING_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        posting: null,
      };
    case POSTING_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        message: {
          title: 'Posting',
          message: 'Posting was saved.',
          severity: SEVERITY.SUCCESS,
        },
      };
    case POSTING_PUBLISH:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case POSTING_PUBLISH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        message: {
          title: 'Posting',
          message: 'Posting publish was requested',
          severity: SEVERITY.SUCCESS,
        },
      };

    case POSTING_EDIT:
      return {
        ...state,
        posting: null,
        loading: true,
        error: null,
      };
    case POSTING_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        message: {
          title: 'Posting',
          message: 'Posting was saved.',
          severity: SEVERITY.SUCCESS,
        },
      };
    case CLEAR_POSTING_MESSAGE:
      return {
        ...state,
        message: null,
      };

    case POSTING_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        posting: null,
      };
    case POSTING_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        posting: null,
        message: {
          title: 'Posting',
          message: payload,
          severity: SEVERITY.DANGER,
        },
      };

    default:
      return { ...state };
  }
}
