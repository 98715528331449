export const CLIENT_INFO_GET_BY_ID = 'CLIENT_INFO_GET_BY_ID';
export const CLIENT_INFO_GET_BY_ID_SUCCESS = 'CLIENT_INFO_GET_BY_ID_SUCCESS';
export const CLIENT_INFO_GET_BY_ID_ERROR = 'CLIENT_INFO_GET_BY_ID_ERROR';

export const CLIENT_INFO_UPDATE = 'CLIENT_INFO_UPDATE';
export const CLIENT_INFO_UPDATE_SUCCESS = 'CLIENT_INFO_UPDATE_SUCCESS';
export const CLIENT_INFO_UPDATE_ERROR = 'CLIENT_INFO_UPDATE_ERROR';

export const CLIENT_INFO_SYNC = 'CLIENT_INFO_SYNC';
export const CLIENT_INFO_SYNC_SUCCESS = 'CLIENT_INFO_SYNC_SUCCESS';
export const CLIENT_INFO_SYNC_ERROR = 'CLIENT_INFO_SYNC_ERROR';
