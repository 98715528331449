import { SEVERITY } from '../../helpers/Utils';
import {
  EMPLOYER_INFO_GET_BY_ID,
  EMPLOYER_INFO_GET_BY_ID_ERROR,
  EMPLOYER_INFO_GET_BY_ID_SUCCESS,
  EMPLOYER_INFO_UPDATE,
  EMPLOYER_INFO_UPDATE_SUCCESS,
  EMPLOYER_INFO_UPDATE_ERROR,
  EMPLOYER_INFO_ADD_NEW,
  EMPLOYER_INFO_ADD_NEW_ERROR,
  EMPLOYER_INFO_SYNC,
  EMPLOYER_INFO_SYNC_SUCCESS,
  EMPLOYER_INFO_SYNC_ERROR,
  EMPLOYER_INFO_GET_BY_USER,
  EMPLOYER_INFO_GET_BY_USER_SUCCESS,
  EMPLOYER_INFO_GET_BY_USER_ERROR,
  CLEAR_EMPLOYER_INFO_MESSAGE,
} from './types';
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
  hasEmployer: true,
  syncLoading: false,
  lastUpdate: null,
  message: null,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EMPLOYER_INFO_GET_BY_ID:
    case EMPLOYER_INFO_UPDATE:
    case EMPLOYER_INFO_ADD_NEW:
    case EMPLOYER_INFO_GET_BY_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EMPLOYER_INFO_GET_BY_USER_SUCCESS:
    case EMPLOYER_INFO_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
        lastUpdate: new Date(),
      };

    case EMPLOYER_INFO_SYNC_SUCCESS:
    case EMPLOYER_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
        syncLoading: false,
        lastUpdate: new Date(),
        message: {
          title: 'Company Info',
          message: 'Company was saved',
          severity: SEVERITY.SUCCESS,
        },
      };

    case CLEAR_EMPLOYER_INFO_MESSAGE:
      return {
        ...state,
        loading: false,
        message: null,
      };
    case EMPLOYER_INFO_GET_BY_USER_ERROR:
      return {
        ...state,
        loading: false,
        hasEmployer: false,
        error: payload,
      };
    case EMPLOYER_INFO_SYNC_ERROR:
    case EMPLOYER_INFO_GET_BY_ID_ERROR:
    case EMPLOYER_INFO_UPDATE_ERROR:
    case EMPLOYER_INFO_ADD_NEW_ERROR:
      return {
        ...state,
        loading: false,
        hasEmployer: false,
        error: payload,
        message: {
          title: 'Company Info',
          message: payload,
          severity: SEVERITY.DANGER,
        },
      };

    case EMPLOYER_INFO_SYNC:
      return {
        ...state,
        loading: true,
        error: null,
        syncLoading: true,
      };

    default:
      return { ...state };
  }
}
