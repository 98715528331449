import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { CLIENT_INFO_GET_BY_ID, CLIENT_INFO_UPDATE, CLIENT_INFO_SYNC } from './types';

import {
  getClientInfoByIdError,
  getClientInfoByIdSuccess,
  updateClientInfoError,
  updateClientInfoSuccess,
  syncClientInfoError,
  syncClientInfoSuccess,
} from './actions';

const getClientInfoByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ClientProfile/client/${id}`, await authHeader());
};

const getClientInfoByClientReferenceNumberRequest = async clientReferenceNumber => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/clientReferenceNumber/${clientReferenceNumber}`,
    await authHeader()
  );
};

const getTransactionLogsByClientIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/client/${id}/transaction/logs`,
    await authHeader()
  );
};

const getLiveClientTransactions = async () => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/transaction/live`,
    await authHeader()
  );
};

const getTransactionErrorsByClientIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/client/${id}/transaction/live`,
    await authHeader()
  );
};

const putClientInfoTransactionIgnoreRequest = async id => {
  return axios.put(
    `${Config.apiServerHost}/api/ClientProfile/client/transaction/${id}/ignore`,
    null,
    await authHeader()
  );
};

const syncViaGoverningTransactionRequest = async requestId => {
  return axios.post(
    `${Config.apiServerHost}/api/ClientProfile/client/transaction/${requestId}/sync`,
    null,
    await authHeader()
  );
};

function* getClientInfoById({ payload }) {
  try {
    let response = yield call(getClientInfoByIdRequest, payload);
    yield put(getClientInfoByIdSuccess(response.data));
  } catch (error) {
    const { data } = error.response;
    let errorMessage = {
      title: error?.response?.statusText ?? 'Error',
      message: data?.message ?? 'Something was wrong.',
    };
    yield put(getClientInfoByIdError(errorMessage));
  }
}

const updateClientInfoRequest = async ({ servicePlanHeaderId, clientInfo }) => {
  return axios.put(
    `${Config.apiServerHost}/api/ClientProfile/client/${clientInfo.clientId}?servicePlanHeaderId=${servicePlanHeaderId}`,
    clientInfo,
    await authHeader()
  );
};

const syncClientInfoRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/ClientProfile/client/${data.clientId}/sync?servicePlanHeaderId=${data.servicePlanHeaderId}&clientReferenceNumberOverride=${data.clientReferenceNumberOverride}`,
    '{}',
    await authHeader()
  );
};

function* updateClientInfo({ payload }) {
  try {
    let response = yield call(updateClientInfoRequest, payload);
    yield put(updateClientInfoSuccess(response.data));
  } catch (error) {
    yield put(updateClientInfoError(error?.response?.statusText));
  }
}

function* syncClientInfo({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, clientReferenceNumberOverride } = payload;
    let response = yield call(syncClientInfoRequest, {
      clientId,
      servicePlanHeaderId,
      clientReferenceNumberOverride,
    });
    yield put(syncClientInfoSuccess(response.data));
  } catch (error) {
    yield put(syncClientInfoError(error?.response?.statusText));
  }
}

export function* watchGettingClientById() {
  yield takeEvery(CLIENT_INFO_GET_BY_ID, getClientInfoById);
}

export function* watchUpdatingClientInfo() {
  yield takeEvery(CLIENT_INFO_UPDATE, updateClientInfo);
}

export function* watchSyncingClientInfo() {
  yield takeEvery(CLIENT_INFO_SYNC, syncClientInfo);
}

export default function* rootSaga() {
  yield all([
    fork(watchGettingClientById),
    fork(watchUpdatingClientInfo),
    fork(watchSyncingClientInfo),
  ]);
}

export {
  getTransactionLogsByClientIdRequest,
  getTransactionErrorsByClientIdRequest,
  getLiveClientTransactions,
  putClientInfoTransactionIgnoreRequest,
  syncViaGoverningTransactionRequest,
  getClientInfoByClientReferenceNumberRequest,
  getClientInfoByIdRequest,
};
